import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RightSidebarState {
  docPoint: number;
  docRanking: number;
  commentPoint: number;
  commentRanking: number;
  totalPoint: number;
  totalRanking: number;
}

const initialState: RightSidebarState = {
  docPoint: 0,
  docRanking: 0,
  commentPoint: 0,
  commentRanking: 0,
  totalPoint: 0,
  totalRanking: 0,
};

export const rightSidebar = createSlice({
  name: "rightSidebar",
  initialState,
  reducers: {
    setDocPoint(state, action: PayloadAction<number>) {
      state.docPoint = action.payload;
    },

    setDocRanking(state, action: PayloadAction<number>) {
      state.docRanking = action.payload;
    },

    setCommentPoint(state, action: PayloadAction<number>) {
      state.commentPoint = action.payload;
    },

    setCommentRanking(state, action: PayloadAction<number>) {
      state.commentRanking = action.payload;
    },

    setTotalPoint(state, action: PayloadAction<number>) {
      state.totalPoint = action.payload;
    },

    setTotalRanking(state, action: PayloadAction<number>) {
      state.totalRanking = action.payload;
    },
  },
});

export const rightSidebarActions = rightSidebar.actions;
export default rightSidebar.reducer;
