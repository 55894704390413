import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageState } from "./message";

export interface NotificationState {
  messages: MessageState[];
  fetching: number;
  fetched: number;
  blocked: string[];
}

const initialState: NotificationState = {
  messages: [],
  fetching: 0,
  fetched: 0,
  blocked: [],
};

export const notification = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetMessages(state) {
      state.messages = [];
    },

    appendMessage(state, action: PayloadAction<MessageState>) {
      const isEqualId = (message: MessageState) =>
        message.documentId === action.payload.documentId &&
        message.commentId === action.payload.commentId &&
        message.replyId === action.payload.replyId;

      if (state.messages.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.messages = [...state.messages, action.payload].sort((a, b) => {
          return a.timestamp < b.timestamp ? 1 : -1;
        });
      }
    },

    deleteComment(state, action: PayloadAction<string>) {
      const isEqualId = (message: MessageState) =>
        message.commentId === action.payload;

      const index = state.messages.findIndex(isEqualId);

      state.messages[index].isDeleted = true;
    },

    deleteReply(state, action: PayloadAction<string>) {
      const isEqualId = (message: MessageState) =>
        message.replyId === action.payload;

      const index = state.messages.findIndex(isEqualId);

      state.messages[index].isDeleted = true;
    },

    resetFetching(state) {
      state.fetching = 0;
    },

    incrementFetching(state, action: PayloadAction<number>) {
      state.fetching += action.payload;
    },

    resetFetched(state) {
      state.fetched = 0;
    },

    incrementFetched(state, action: PayloadAction<number>) {
      state.fetched += action.payload;
    },

    resetBlocked(state) {
      state.blocked = [];
    },

    appendBlocked(state, action: PayloadAction<string>) {
      state.blocked = [...state.blocked, action.payload];
    },
  },
});

export const notificationActions = notification.actions;
export default notification.reducer;
