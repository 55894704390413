import { coreActions } from "../../module/core";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Loading } from "../loading/loading";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useRef } from "react";
import "./notice_write.css";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import ReactQuill from "react-quill";
import { noticeWriteActions } from "../../module/notice_write";
import { getAuth } from "firebase/auth";
import uuid from "react-uuid";
import LinearProgress from "@mui/material/LinearProgress";

export function NoticeWrite() {
  const isAdmin = useAppSelector((state) => state.core.isAdmin);
  const isLoading = useAppSelector((state) => state.core.isLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(coreActions.setFocus("notice"));

    dispatch(noticeWriteActions.setIsImageLoading(false));
    dispatch(noticeWriteActions.setContent(""));
  }, []);

  if (isAdmin) {
    return (
      <div className="NoticeWrite">{isLoading ? <Loading /> : <Body />}</div>
    );
  } else {
    return <div className="NoticeWrite"></div>;
  }
}

function Body() {
  const quillRef = useRef<ReactQuill>(null);

  const content = useAppSelector((state) => state.noticeWrite.content);

  const dispatch = useAppDispatch();

  const imageHandler = () => {
    const editor = quillRef.current!.getEditor();

    const storage = getStorage();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click();

    input.addEventListener("change", async () => {
      const range = editor.getSelection(true);
      try {
        if (input.files && range) {
          dispatch(noticeWriteActions.setIsImageLoading(true));

          const uploadPromises = Array.from(input.files).map(async (file) => {
            const fileId = `${Date.now()}-${uuid()}`;
            const storageRef = ref(storage, `image/${fileId}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);

            editor.insertEmbed(range.index, "image", url);
            editor.setSelection(range.index + 1, 0);
          });
          await Promise.all(uploadPromises);

          dispatch(noticeWriteActions.setIsImageLoading(false));
        }
      } catch (error) {
        const auth = getAuth();

        if (auth.currentUser) {
          alert("서버와의 통신에 실패하였습니다.");
        } else {
          alert("글 쓰기는 로그인하여야 가능합니다.");
        }

        dispatch(noticeWriteActions.setIsImageLoading(false));
      }
    });

    editor.on("text-change", async (_, oldDelta, source) => {
      if (source !== "user") {
        return;
      }

      const imageUrls = editor
        .getContents()
        .diff(oldDelta)
        .ops?.filter((i) => i.insert && i.insert.image)
        .map((i) => i.insert.image);

      for (let i = 0; i < imageUrls!.length; i++) {
        const imageUrl = imageUrls![i];

        const baseUrl =
          "https://firebasestorage.googleapis.com/v0/b/giggles-62f17.appspot.com/o/";

        let imagePath: string = imageUrl.replace(baseUrl, "");

        const indexOfEndPath = imagePath.indexOf("?");

        imagePath = imagePath.substring(0, indexOfEndPath);

        imagePath = imagePath.replace("%2F", "/");

        const storageRef = ref(storage, imagePath);

        /*
                                getDownloadURL(storageRef)
                                  .then((_) => {
                                    deleteObject(storageRef);
                                  })
                                  .catch((_) => {});
                                */
      }
    });
  };

  const toolbarOptions = useMemo(
    () => ({
      toolbar: {
        container: [[], [{}], ["image", "video", "link"]],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  );

  return (
    <div className="NoticeWrite-Body">
      <Header />
      <Sidebar />
      <Title />
      <ReactQuill
        className="NoticeWrite-Quill"
        ref={quillRef}
        modules={toolbarOptions}
        value={content}
        onChange={(content) => dispatch(noticeWriteActions.setContent(content))}
      />
      <Submit />
    </div>
  );
}

function Title() {
  const isImageLoading = useAppSelector(
    (state) => state.noticeWrite.isImageLoading,
  );

  if (isImageLoading) {
    return (
      <div className="NoticeWrite-Title">
        <h1>공지사항 작성</h1>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <div className="NoticeWrite-Title">
        <h1>공지사항 작성</h1>
      </div>
    );
  }
}

function LoadingBar() {
  return (
    <div className="NoticeWrite-LoadingBar">
      <LinearProgress />
    </div>
  );
}

function Submit() {
  const navigate = useNavigate();

  const content = useAppSelector((state) => state.noticeWrite.content);

  const dispatch = useAppDispatch();

  const onClick = async () => {
    dispatch(coreActions.setIsLoading(true));

    const auth = getAuth();
    const firestore = getFirestore();

    const timestamp = Date.now();

    const id = uuid();

    const documentId = timestamp.toString() + "-" + id;

    await setDoc(doc(firestore, "notice", documentId), {
      documentId: documentId,
      authorUid: auth.currentUser!.uid,
      content: content,
      timestamp: timestamp,
    });

    navigate("/notice");

    dispatch(coreActions.setIsLoading(false));
  };

  return (
    <div className="NoticeWrite-Submit">
      <button onClick={onClick}>글 쓰기</button>
    </div>
  );
}
