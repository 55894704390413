import "./mobile_email_verification.css";
import logoTransparent from "../../asset/image/logo_transparent.png";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import { MobileLoading } from "../loading/mobile_loading";
import { MobileHeader } from "../mobile_header";
import { emailVerificationActions } from "../../module/email_verification";
import { coreActions } from "../../module/core";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

export function MobileEmailVerification() {
  const isLoading = useAppSelector((state) => state.core.isLoading);

  return (
    <div className="MobileEmailVerification">
      {isLoading ? <MobileLoading /> : <Body />}
    </div>
  );
}

function Body() {
  return (
    <div className={"MobileEmailVerification-Body"}>
      <MobileHeader />
      <Title />
      <Background />
      <Inputs />
      <Continue />
    </div>
  );
}

function Title() {
  return (
    <div className="MobileEmailVerification-Title">
      <h1>이메일 재인증</h1>
      <p>
        이메일 주소와 비밀번호를 입력하시면 이메일 재인증 링크를 보내드립니다
      </p>
    </div>
  );
}

function Background() {
  return (
    <div className="MobileEmailVerification-Background">
      <img src={logoTransparent} />
    </div>
  );
}

function Inputs() {
  return (
    <div className="MobileEmailVerification-Inputs">
      <EmailInput />
      <PasswordInput />
    </div>
  );
}

function EmailInput() {
  return (
    <div className="MobileEmailVerification-EmailInput">
      <EmailInputHint />
      <EmailInputForm />
    </div>
  );
}

function EmailInputHint() {
  return (
    <div className="MobileEmailVerification-EmailInputHint">
      <h1>이메일 주소</h1>
      <p>*</p>
    </div>
  );
}

function EmailInputForm() {
  const email = useAppSelector((state) => state.emailVerification.email);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(emailVerificationActions.setEmail(e.target.value));
  };

  return (
    <div className="MobileEmailVerification-EmailInputForm">
      <form>
        <input
          type="text"
          placeholder="이메일 주소"
          required
          value={email}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function PasswordInput() {
  return (
    <div className="MobileEmailVerification-PasswordInput">
      <PasswordInputHint />
      <PasswordInputForm />
    </div>
  );
}

function PasswordInputHint() {
  return (
    <div className="MobileEmailVerification-PasswordInputHint">
      <h1>비밀번호</h1>
      <p>*</p>
    </div>
  );
}

function PasswordInputForm() {
  const password = useAppSelector((state) => state.emailVerification.password);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(emailVerificationActions.setPassword(e.target.value));
  };

  return (
    <div className="MobileEmailVerification-PasswordInputForm">
      <form>
        <input
          type="password"
          placeholder="비밀번호"
          required
          value={password}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function Continue() {
  const email = useAppSelector((state) => state.emailVerification.email);
  const password = useAppSelector((state) => state.emailVerification.password);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onClick = async () => {
    if (email !== "" && password !== "") {
      dispatch(coreActions.setIsLoading(true));

      const auth = getAuth();

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;

          sendEmailVerification(user);

          signOut(auth);

          dispatch(coreActions.setIsLoading(false));

          alert("인증 이메일이 전송되었습니다.");

          navigate("/");
        })
        .catch((error) => {
          const errorCode = error.code;

          if (errorCode === "auth/invalid-credential") {
            alert(
              "해당 유저를 찾을 수 없습니다. 아이디와 비밀번호를 다시 확인해주세요.",
            );
          } else if (errorCode === "auth/invalid-email") {
            alert("이메일 주소가 올바르지 않습니다.");
          } else if (errorCode === "auth/missing-password") {
            alert("비밀번호를 입력해주세요.");
          } else {
            alert("서버와의 통신에 실패하였습니다.");
          }

          dispatch(coreActions.setIsLoading(false));
        });
    } else {
      alert("이메일 주소를 입력해주세요");
    }
  };

  if (email !== "" && password !== "") {
    return (
      <div className="MobileEmailVerification-ContinueActive">
        <button onClick={onClick}>링크 요청</button>
      </div>
    );
  } else {
    return (
      <div className="MobileEmailVerification-ContinueInactive">
        <button onClick={onClick}>링크 요청</button>
      </div>
    );
  }
}
