import "./mobile_sign_up.css";
import logoTransparent from "../../asset/image/logo_transparent.png";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import { MobileLoading } from "../loading/mobile_loading";
import { MobileHeader } from "../mobile_header";
import { signUpActions } from "../../module/sign_up";
import { coreActions } from "../../module/core";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export function MobileSignUp() {
  const isLoading = useAppSelector((state) => state.core.isLoading);

  return (
    <div className="MobileSignUp">
      {isLoading ? <MobileLoading /> : <Body />}
    </div>
  );
}

function Body() {
  return (
    <div className={"MobileSignUp-Body"}>
      <MobileHeader />
      <Title />
      <Background />
      <Required />
      <Inputs />
      <Continue />
    </div>
  );
}

function Title() {
  return (
    <div className="MobileSignUp-Title">
      <h1>회원가입</h1>
    </div>
  );
}

function Background() {
  return (
    <div className="MobileSignUp-Background">
      <img src={logoTransparent} />
    </div>
  );
}

function Required() {
  return (
    <div className="MobileSignUp-Required">
      <h1>필수</h1>
      <p>*</p>
    </div>
  );
}

function Inputs() {
  return (
    <div className="MobileSignUp-Inputs">
      <EmailInput />
      <PasswordInput />
      <PasswordCheckInput />
      <NameInput />
      <NicknameInput />
      <Gender />
      <DoB />
    </div>
  );
}

function EmailInput() {
  return (
    <div className="MobileSignUp-EmailInput">
      <EmailInputHint />
      <EmailInputForm />
    </div>
  );
}

function EmailInputHint() {
  return (
    <div className="MobileSignUp-EmailInputHint">
      <h1>이메일 주소</h1>
      <p>*</p>
    </div>
  );
}

function EmailInputForm() {
  const email = useAppSelector((state) => state.signUp.email);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(signUpActions.setEmail(e.target.value));
  };

  return (
    <div className="MobileSignUp-EmailInputForm">
      <form>
        <input
          type="text"
          placeholder="ex: giggles9489@naver.com"
          required
          value={email}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function PasswordInput() {
  return (
    <div className="MobileSignUp-PasswordInput">
      <PasswordInputHint />
      <PasswordInputForm />
    </div>
  );
}

function PasswordInputHint() {
  return (
    <div className="MobileSignUp-PasswordInputHint">
      <h1>비밀번호</h1>
      <p>*</p>
    </div>
  );
}

function PasswordInputForm() {
  const password = useAppSelector((state) => state.signUp.password);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(signUpActions.setPassword(e.target.value));
  };

  return (
    <div className="MobileSignUp-PasswordInputForm">
      <form>
        <input
          type="password"
          placeholder="비밀번호"
          required
          value={password}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function PasswordCheckInput() {
  return (
    <div className="MobileSignUp-PasswordCheckInput">
      <PasswordCheckInputHint />
      <PasswordCheckInputForm />
    </div>
  );
}

function PasswordCheckInputHint() {
  return (
    <div className="MobileSignUp-PasswordCheckInputHint">
      <h1>비밀번호 재확인</h1>
      <p>*</p>
    </div>
  );
}

function PasswordCheckInputForm() {
  const passwordCheck = useAppSelector((state) => state.signUp.passwordCheck);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(signUpActions.setPasswordCheck(e.target.value));
  };

  return (
    <div className="MobileSignUp-PasswordCheckInputForm">
      <form>
        <input
          type="password"
          placeholder="비밀번호 재확인"
          required
          value={passwordCheck}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function NameInput() {
  return (
    <div className="MobileSignUp-NameInput">
      <NameInputHint />
      <NameInputForm />
    </div>
  );
}

function NameInputHint() {
  return (
    <div className="MobileSignUp-NameInputHint">
      <h1>아이디</h1>
      <p>*</p>
    </div>
  );
}

function NameInputForm() {
  const name = useAppSelector((state) => state.signUp.name);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (value > 13) {
      alert("아이디가 너무 깁니다.");
    } else {
      dispatch(signUpActions.setName(value));
    }
  };

  return (
    <div className="MobileSignUp-NameInputForm">
      <form>
        <input
          type="text"
          placeholder="영문, 변경 불가능, 13자 미만"
          required
          value={name}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function NicknameInput() {
  return (
    <div className="MobileSignUp-NicknameInput">
      <NicknameInputHint />
      <NicknameInputForm />
    </div>
  );
}

function NicknameInputHint() {
  return (
    <div className="MobileSignUp-NicknameInputHint">
      <h1>닉네임</h1>
      <p>*</p>
    </div>
  );
}

function NicknameInputForm() {
  const nickname = useAppSelector((state) => state.signUp.nickname);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    if (e.target.value.length > 10) {
      alert("닉네임이 너무 깁니다.");
    } else {
      dispatch(signUpActions.setNickname(e.target.value));
    }
  };

  return (
    <div className="MobileSignUp-NicknameInputForm">
      <form>
        <input
          type="text"
          placeholder="닉네임, 10자 미만"
          required
          value={nickname}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function Gender() {
  return (
    <div className="MobileSignUp-Gender">
      <GenderInputHint />
      <GenderInputForm />
    </div>
  );
}

function GenderInputHint() {
  return (
    <div className="MobileSignUp-GenderInputHint">
      <h1>성별</h1>
    </div>
  );
}

function GenderInputForm() {
  const [gender, setGender] = useState("");

  const onChange = (e: any) => {
    setGender(e.target.value);
  };

  return (
    <div className="MobileSignUp-GenderInputForm">
      <form>
        <input
          type="text"
          placeholder="성별 (예: 남성, 여성)"
          value={gender}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function DoB() {
  return (
    <div className="MobileSignUp-DoB">
      <DoBInputHint />
      <DoBInputForm />
    </div>
  );
}

function DoBInputHint() {
  return (
    <div className="MobileSignUp-DoBInputHint">
      <h1>생년월일</h1>
    </div>
  );
}

function DoBInputForm() {
  const [dob, setDoB] = useState("");

  const onChange = (e: any) => {
    setDoB(e.target.value);
  };

  return (
    <div className="MobileSignUp-DoBInputForm">
      <form>
        <input
          type="text"
          placeholder="생년월일 (예: 990101)"
          value={dob}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function Continue() {
  const email = useAppSelector((state) => state.signUp.email);
  const password = useAppSelector((state) => state.signUp.password);
  const name = useAppSelector((state) => state.signUp.name);
  const nickname = useAppSelector((state) => state.signUp.nickname);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (email !== "" && password !== "" && name !== "" && nickname !== "") {
      let isDuplicated = false;
      dispatch(coreActions.setIsLoading(true));

      const qName = query(
        collection(firestore, "user"),
        where("name", "==", name),
      );
      await getDocs(qName).then((snapshot) => {
        if (!snapshot.empty) {
          alert("해당 아이디는 이미 사용 중입니다.");

          isDuplicated = true;

          dispatch(coreActions.setIsLoading(false));
        }
      });

      const qNickname = query(
        collection(firestore, "user"),
        where("nickname", "==", nickname),
      );
      await getDocs(qNickname).then((snapshot) => {
        if (!snapshot.empty) {
          alert("해당 닉네임은 이미 사용 중입니다.");

          isDuplicated = true;

          dispatch(coreActions.setIsLoading(false));
        }
      });

      if (!isDuplicated) {
        await createUserWithEmailAndPassword(auth, email, password)
          .then((_) => {})
          .catch((error) => {
            const errorCode = error.code;

            if (auth.currentUser !== null) {
              deleteUser(auth.currentUser);
              signOut(auth);
            }

            if (errorCode === "auth/email-already-in-use") {
              alert("해당 이메일은 이미 사용 중입니다.");
            } else if (errorCode === "auth/invalid-email") {
              alert("이메일 주소가 올바르지 않습니다.");
            } else if (errorCode === "auth/missing-password") {
              alert("비밀번호를 입력해주세요.");
            } else {
              alert("서버와의 통신에 실패하였습니다.");
            }

            dispatch(coreActions.setIsLoading(false));
          });

        const uid = auth.currentUser!.uid;

        const userDocRef = doc(firestore, "user", uid);
        await setDoc(userDocRef, {
          uid: uid,
          email: email,
          name: name,
          nickname: nickname,
        })
          .then(() => {})
          .catch((_) => {
            deleteUser(auth.currentUser!);
            signOut(auth);

            alert("서버와의 통신에 실패하였습니다.");

            dispatch(coreActions.setIsLoading(false));
          });

        const user = auth.currentUser!;

        await sendEmailVerification(user)
          .then(() => {
            dispatch(coreActions.setIsLoading(false));

            signOut(auth);
            navigate("/");

            alert("메일함으로 가서 이메일을 인증해주세요");
          })
          .catch((_) => {
            deleteUser(user);
            signOut(auth);

            const userDocRef = doc(firestore, "user", uid);
            deleteDoc(userDocRef);

            alert("서버와의 통신에 실패하였습니다.");

            dispatch(coreActions.setIsLoading(false));
          });
      }
    } else {
      alert("모두 기입해주십시오.");
    }
  };

  if (email !== "" && password !== "" && name !== "" && nickname !== "") {
    return (
      <div className="MobileSignUp-ContinueActive">
        <button onClick={onClick}>가입하기</button>
      </div>
    );
  } else {
    return (
      <div className="MobileSignUp-ContinueInactive">
        <button onClick={onClick}>가입하기</button>
      </div>
    );
  }
}
