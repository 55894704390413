import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";
import { UserState } from "./user";

export interface ProfileViewState {
  uid: string;
  avatarUrl: string;
  name: string;
  nickname: string;
  numFollowers: number;
  numFollowings: number;
  numPosts: number;
  numBookmarks: number;
  bio: string;
  docPoint: number;
  docRanking: number;
  commentPoint: number;
  commentRanking: number;
  totalPoint: number;
  totalRanking: number;
  tab: string;
  followers: UserState[];
  followings: UserState[];
  posts: DocumentState[];
  isFollowing: boolean;
  followerFetching: number;
  followerFetched: number;
  followerBlocked: string[];
  followingFetching: number;
  followingFetched: number;
  followingBlocked: string[];
  postFetching: number;
  postFetched: number;
  postBlocked: string[];
}

const initialState: ProfileViewState = {
  uid: "",
  avatarUrl: "",
  name: "",
  nickname: "",
  numFollowers: 0,
  numFollowings: 0,
  numPosts: 0,
  numBookmarks: 0,
  bio: "",
  docPoint: 0,
  docRanking: 0,
  commentPoint: 0,
  commentRanking: 0,
  totalPoint: 0,
  totalRanking: 0,
  tab: "followers",
  followers: [],
  followings: [],
  posts: [],
  isFollowing: false,
  followerFetching: 0,
  followerFetched: 0,
  followerBlocked: [],
  followingFetching: 0,
  followingFetched: 0,
  followingBlocked: [],
  postFetching: 0,
  postFetched: 0,
  postBlocked: [],
};

export const profileView = createSlice({
  name: "profileView",
  initialState,
  reducers: {
    setUid(state, action: PayloadAction<string>) {
      state.uid = action.payload;
    },

    setAvatarUrl(state, action: PayloadAction<string>) {
      state.avatarUrl = action.payload;
    },

    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },

    setNickname(state, action: PayloadAction<string>) {
      state.nickname = action.payload;
    },

    setNumFollowers(state, action: PayloadAction<number>) {
      state.numFollowers = action.payload;
    },

    setNumFollowings(state, action: PayloadAction<number>) {
      state.numFollowings = action.payload;
    },

    setNumPosts(state, action: PayloadAction<number>) {
      state.numPosts = action.payload;
    },

    setNumBookmarks(state, action: PayloadAction<number>) {
      state.numBookmarks = action.payload;
    },

    setBio(state, action: PayloadAction<string>) {
      state.bio = action.payload;
    },

    setDocPoint(state, action: PayloadAction<number>) {
      state.docPoint = action.payload;
    },

    setDocRanking(state, action: PayloadAction<number>) {
      state.docRanking = action.payload;
    },

    setCommentPoint(state, action: PayloadAction<number>) {
      state.commentPoint = action.payload;
    },

    setCommentRanking(state, action: PayloadAction<number>) {
      state.commentRanking = action.payload;
    },

    setTotalPoint(state, action: PayloadAction<number>) {
      state.totalPoint = action.payload;
    },

    setTotalRanking(state, action: PayloadAction<number>) {
      state.totalRanking = action.payload;
    },

    setTab(state, action: PayloadAction<string>) {
      state.tab = action.payload;
    },

    resetFollowers(state) {
      state.followers = [];
    },

    appendFollower(state, action: PayloadAction<UserState>) {
      const isEqualId = (follower: UserState) =>
        follower.uid === action.payload.uid;

      if (state.followers.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.followers = [...state.followers, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    resetFollowings(state) {
      state.followings = [];
    },

    appendFollowing(state, action: PayloadAction<UserState>) {
      const isEqualId = (following: UserState) =>
        following.uid === action.payload.uid;

      if (state.followings.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.followings = [...state.followings, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    deleteFollowing(state, action: PayloadAction<UserState>) {
      const isEqualId = (following: UserState) =>
        following.uid === action.payload.uid;

      const index = state.followings.findIndex(isEqualId);

      if (index >= 0) {
        state.followings = [
          ...state.followings.slice(0, index),
          ...state.followings.slice(index + 1, state.followings.length),
        ];
      }
    },

    resetPosts(state) {
      state.posts = [];
    },

    appendPost(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (post: DocumentState) =>
        post.documentId === action.payload.documentId;

      if (state.posts.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.posts = [...state.posts, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    removeDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      const index = state.posts.findIndex(isEqualId);

      if (index >= 0) {
        state.posts = [
          ...state.posts.slice(0, index),
          ...state.posts.slice(index + 1, state.posts.length),
        ];
      }
    },

    setIsFollowing(state, action: PayloadAction<boolean>) {
      state.isFollowing = action.payload;
    },

    setFollowersFollowing(state, action: PayloadAction<UserState>) {
      const isEqualId = (user: UserState) => user.uid === action.payload.uid;

      const index = state.followers.findIndex(isEqualId);

      if (index >= 0) {
        state.followers[index] = {
          ...state.followers[index],
          isFollowing: true,
        };
      }
    },

    unsetFollowersFollowing(state, action: PayloadAction<UserState>) {
      const isEqualId = (user: UserState) => user.uid === action.payload.uid;

      const index = state.followers.findIndex(isEqualId);

      if (index >= 0) {
        state.followers[index] = {
          ...state.followers[index],
          isFollowing: false,
        };
      }
    },

    setDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.posts.findIndex(isEqualId);

      if (index >= 0) {
        state.posts[index].clickUp = true;
        state.posts[index].numUps += 1;
      }
    },

    unsetDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.posts.findIndex(isEqualId);

      if (index >= 0) {
        state.posts[index].clickUp = false;
        state.posts[index].numUps -= 1;
      }
    },

    setDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.posts.findIndex(isEqualId);

      if (index >= 0) {
        state.posts[index].clickDown = true;
        state.posts[index].numDowns += 1;
      }
    },

    unsetDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.posts.findIndex(isEqualId);

      if (index >= 0) {
        state.posts[index].clickDown = false;
        state.posts[index].numDowns -= 1;
      }
    },

    setDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.posts.findIndex(isEqualId);

      if (index >= 0) {
        state.posts[index].clickBookmark = true;
      }
    },

    unsetDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.posts.findIndex(isEqualId);

      if (index >= 0) {
        state.posts[index].clickBookmark = false;
      }
    },

    resetFollowerFetching(state) {
      state.followerFetching = 0;
    },

    incrementFollowerFetching(state, action: PayloadAction<number>) {
      state.followerFetching += action.payload;
    },

    resetFollowerFetched(state) {
      state.followerFetched = 0;
    },

    incrementFollowerFetched(state, action: PayloadAction<number>) {
      state.followerFetched += action.payload;
    },

    resetFollowerBlocked(state) {
      state.followerBlocked = [];
    },

    appendFollowerBlocked(state, action: PayloadAction<string>) {
      state.followerBlocked = [...state.followerBlocked, action.payload];
    },

    resetFollowingFetching(state) {
      state.followingFetching = 0;
    },

    incrementFollowingFetching(state, action: PayloadAction<number>) {
      state.followingFetching += action.payload;
    },

    resetFollowingFetched(state) {
      state.followingFetched = 0;
    },

    incrementFollowingFetched(state, action: PayloadAction<number>) {
      state.followingFetched += action.payload;
    },

    resetFollowingBlocked(state) {
      state.followingBlocked = [];
    },

    appendFollowingBlocked(state, action: PayloadAction<string>) {
      state.followingBlocked = [...state.followingBlocked, action.payload];
    },

    resetPostFetching(state) {
      state.postFetching = 0;
    },

    incrementPostFetching(state, action: PayloadAction<number>) {
      state.postFetching += action.payload;
    },

    resetPostFetched(state) {
      state.postFetched = 0;
    },

    incrementPostFetched(state, action: PayloadAction<number>) {
      state.postFetched += action.payload;
    },

    resetPostBlocked(state) {
      state.postBlocked = [];
    },

    appendPostBlocked(state, action: PayloadAction<string>) {
      state.postBlocked = [...state.postBlocked, action.payload];
    },
  },
});

export const profileViewActions = profileView.actions;
export default profileView.reducer;
