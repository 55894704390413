import sidebarBase from "../asset/image/sidebar_base.png";
import sidebarHome from "../asset/image/sidebar_home.png";
import sidebarSearch from "../asset/image/sidebar_search.png";
import sidebarPlus from "../asset/image/sidebar_plus.png";
import sidebarBell from "../asset/image/sidebar_bell.png";
import sidebarProfile from "../asset/image/sidebar_profile.png";
import sidebarHomeFocus from "../asset/image/sidebar_home_focus.png";
import sidebarSearchFocus from "../asset/image/sidebar_search_focus.png";
import sidebarBellFocus from "../asset/image/sidebar_bell_focus.png";
import sidebarProfileFocus from "../asset/image/sidebar_profile_focus.png";
import sidebarBellFocusRed from "../asset/image/sidebar_bell_focus_red.png";
import sidebarBellRed from "../asset/image/sidebar_bell_red.png";
import "./sidebar.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../module/hook";
import { coreActions } from "../module/core";
import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";

export function Sidebar() {
  return (
    <div className="Sidebar">
      <SidebarBase />
      <SidebarHome />
      <SidebarSearch />
      <SidebarPlus />
      <SidebarBell />
      <SidebarProfile />
    </div>
  );
}

function SidebarBase() {
  return (
    <div className="Sidebar-Base">
      <img src={sidebarBase} />
    </div>
  );
}

function SidebarHome() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("random"));

    navigate("/");
  };

  if (focus === "random") {
    return (
      <div className="Sidebar-Home">
        <img src={sidebarHomeFocus} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Sidebar-Home">
        <img src={sidebarHome} onClick={onClick} />
      </div>
    );
  }
}

function SidebarSearch() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("search"));

    navigate("/search");
    navigate(0);
  };

  if (focus === "search") {
    return (
      <div className="Sidebar-Search">
        <img src={sidebarSearchFocus} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Sidebar-Search">
        <img src={sidebarSearch} onClick={onClick} />
      </div>
    );
  }
}

function SidebarPlus() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("write"));

    navigate("/write");
  };

  return (
    <div className="Sidebar-Plus">
      <img src={sidebarPlus} onClick={onClick} />
    </div>
  );
}

function SidebarBell() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);
  const [hasNotifications, setHasNotifications] = useState(false);

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    if (auth.currentUser !== null) {
      const notificationCollectionRef = collection(
        firestore,
        "user",
        auth.currentUser!.uid,
        "notification",
      );

      getDocs(notificationCollectionRef).then(
        (notificationCollectionSnapshot) => {
          if (notificationCollectionSnapshot.docs.length !== 0) {
            setHasNotifications(true);
          }
        },
      );
    }
  }, [auth.currentUser]);

  const onClick = () => {
    dispatch(coreActions.setFocus("notification"));

    navigate("/notification");
  };

  if (focus === "notification") {
    if (hasNotifications) {
      return (
        <div className="Sidebar-Bell">
          <img src={sidebarBellFocusRed} onClick={onClick} />
        </div>
      );
    } else {
      return (
        <div className="Sidebar-Bell">
          <img src={sidebarBellFocus} onClick={onClick} />
        </div>
      );
    }
  } else {
    if (hasNotifications) {
      return (
        <div className="Sidebar-Bell">
          <img src={sidebarBellRed} onClick={onClick} />
        </div>
      );
    } else {
      return (
        <div className="Sidebar-Bell">
          <img src={sidebarBell} onClick={onClick} />
        </div>
      );
    }
  }
}

function SidebarProfile() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("profile"));

    navigate("/profile");
  };

  if (focus === "profile") {
    return (
      <div className="Sidebar-Profile">
        <img src={sidebarProfileFocus} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Sidebar-Profile">
        <img src={sidebarProfile} onClick={onClick} />
      </div>
    );
  }
}
