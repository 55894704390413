import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportMessageState } from "./report_message";

export interface AdminState {
  focus: string;
  reportDocumentMessages: ReportMessageState[];
  reportCommentMessages: ReportMessageState[];
  reportReplyMessages: ReportMessageState[];
  uid: string;
  avatarUrl: string;
  name: string;
  nickname: string;
}

const initialState: AdminState = {
  focus: "document",
  reportDocumentMessages: [],
  reportCommentMessages: [],
  reportReplyMessages: [],
  uid: "",
  avatarUrl: "",
  name: "",
  nickname: "",
};

export const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setFocus: (state, action: PayloadAction<string>) => {
      state.focus = action.payload;
    },

    resetReportDocumentMessages(state) {
      state.reportDocumentMessages = [];
    },

    appendReportDocumentMessage(
      state,
      action: PayloadAction<ReportMessageState>,
    ) {
      const isEqualId = (reportDocumentMessage: ReportMessageState) =>
        reportDocumentMessage.reportId === action.payload.reportId;

      if (state.reportDocumentMessages.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.reportDocumentMessages = [
          ...state.reportDocumentMessages,
          action.payload,
        ].sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
      }
    },

    resetReportCommentMessages(state) {
      state.reportCommentMessages = [];
    },

    appendReportCommentMessage(
      state,
      action: PayloadAction<ReportMessageState>,
    ) {
      const isEqualId = (reportCommentMessage: ReportMessageState) =>
        reportCommentMessage.reportId === action.payload.reportId;

      if (state.reportCommentMessages.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.reportCommentMessages = [
          ...state.reportCommentMessages,
          action.payload,
        ].sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
      }
    },

    resetReportReplyMessages(state) {
      state.reportReplyMessages = [];
    },

    appendReportReplyMessage(state, action: PayloadAction<ReportMessageState>) {
      const isEqualId = (reportReplyMessage: ReportMessageState) =>
        reportReplyMessage.reportId === action.payload.reportId;

      if (state.reportReplyMessages.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.reportReplyMessages = [
          ...state.reportReplyMessages,
          action.payload,
        ].sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
      }
    },

    removeReportReplyMessage(state, action: PayloadAction<ReportMessageState>) {
      const isEqualId = (reportMessage: ReportMessageState) =>
        reportMessage.documentId === action.payload.reportId &&
        reportMessage.commentId === action.payload.commentId &&
        reportMessage.reportId === action.payload.reportId;

      const documentsIndex = state.reportDocumentMessages.findIndex(isEqualId);
      const commentsIndex = state.reportCommentMessages.findIndex(isEqualId);
      const repliesIndex = state.reportReplyMessages.findIndex(isEqualId);

      if (documentsIndex >= 0) {
        state.reportDocumentMessages = [
          ...state.reportDocumentMessages.slice(0, documentsIndex),
          ...state.reportDocumentMessages.slice(
            documentsIndex + 1,
            state.reportDocumentMessages.length,
          ),
        ];
      }

      if (commentsIndex >= 0) {
        state.reportCommentMessages = [
          ...state.reportCommentMessages.slice(0, commentsIndex),
          ...state.reportCommentMessages.slice(
            commentsIndex + 1,
            state.reportCommentMessages.length,
          ),
        ];
      }

      if (repliesIndex >= 0) {
        state.reportReplyMessages = [
          ...state.reportReplyMessages.slice(0, repliesIndex),
          ...state.reportReplyMessages.slice(
            repliesIndex + 1,
            state.reportReplyMessages.length,
          ),
        ];
      }
    },

    setUid(state, action: PayloadAction<string>) {
      state.uid = action.payload;
    },

    setAvatarUrl(state, action: PayloadAction<string>) {
      state.avatarUrl = action.payload;
    },

    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },

    setNickname(state, action: PayloadAction<string>) {
      state.nickname = action.payload;
    },
  },
});

export const adminActions = admin.actions;
export default admin.reducer;
