import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DocumentState {
  documentId: string;
  authorUid: string;
  authorAvatarUrl: string;
  authorNickname: string;
  timestamp: number;
  content: string;
  thumbnailContent: any[];
  thumbnailImageSizes: any[];
  hashtags: string[];
  numUps: number;
  numDowns: number;
  numComments: number;
  numTokens: number;
  clickUp: boolean;
  clickDown: boolean;
  clickBookmark: boolean;
}

const initialState: DocumentState = {
  documentId: "",
  authorUid: "",
  authorAvatarUrl: "",
  authorNickname: "",
  timestamp: 0,
  content: "",
  thumbnailContent: [],
  thumbnailImageSizes: [],
  hashtags: [],
  numUps: 0,
  numDowns: 0,
  numComments: 0,
  numTokens: 0,
  clickUp: false,
  clickDown: false,
  clickBookmark: false,
};

export const document = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocumentId(state, action: PayloadAction<string>) {
      state.documentId = action.payload;
    },

    setAuthorUid(state, action: PayloadAction<string>) {
      state.authorUid = action.payload;
    },

    setAuthorAvatarUrl(state, action: PayloadAction<string>) {
      state.authorAvatarUrl = action.payload;
    },

    setAuthorNickname(state, action: PayloadAction<string>) {
      state.authorNickname = action.payload;
    },

    setTimestamp(state, action: PayloadAction<number>) {
      state.timestamp = action.payload;
    },

    setContent(state, action: PayloadAction<string>) {
      state.content = action.payload;
    },

    setThumbnailContent(state, action: PayloadAction<any[]>) {
      state.thumbnailContent = action.payload;
    },

    setThumbnailImageSizes(state, action: PayloadAction<any[]>) {
      state.thumbnailImageSizes = action.payload;
    },

    setHashtags(state, action: PayloadAction<string[]>) {
      state.hashtags = action.payload;
    },

    setNumUps(state, action: PayloadAction<number>) {
      state.numUps = action.payload;
    },

    setNumDowns(state, action: PayloadAction<number>) {
      state.numDowns = action.payload;
    },

    setNumComments(state, action: PayloadAction<number>) {
      state.numComments = action.payload;
    },

    setNumTokens(state, action: PayloadAction<number>) {
      state.numTokens = action.payload;
    },

    setClickUp(state, action: PayloadAction<boolean>) {
      state.clickUp = action.payload;
    },

    setClickDown(state, action: PayloadAction<boolean>) {
      state.clickDown = action.payload;
    },

    setClickBookmark(state, action: PayloadAction<boolean>) {
      state.clickBookmark = action.payload;
    },
  },
});

export const documentActions = document.actions;
export default document.reducer;
