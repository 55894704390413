import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";

export interface FollowingState {
  followings: string[];
  documents: DocumentState[];
  fetching: number;
  fetched: number;
  blocked: string[];
}

const initialState: FollowingState = {
  followings: [],
  documents: [],
  fetching: 0,
  fetched: 0,
  blocked: [],
};

export const following = createSlice({
  name: "following",
  initialState,
  reducers: {
    resetFollowings(state) {
      state.followings = [];
    },

    appendFollowing(state, action: PayloadAction<string>) {
      state.followings = [...state.followings, action.payload];
    },

    resetDocuments(state) {
      state.documents = [];
    },

    appendDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      if (state.documents.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.documents = [...state.documents, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    removeDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      const index = state.documents.findIndex(isEqualId);

      if (index >= 0) {
        state.documents = [
          ...state.documents.slice(0, index),
          ...state.documents.slice(index + 1, state.documents.length),
        ];
      }
    },

    setDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickUp = true;
        state.documents[index].numUps += 1;
      }
    },

    unsetDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickUp = false;
        state.documents[index].numUps -= 1;
      }
    },

    setDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickDown = true;
        state.documents[index].numDowns += 1;
      }
    },

    unsetDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickDown = false;
        state.documents[index].numDowns -= 1;
      }
    },

    setDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickBookmark = true;
      }
    },

    unsetDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickBookmark = false;
      }
    },

    resetFetching(state) {
      state.fetching = 0;
    },

    incrementFetching(state, action: PayloadAction<number>) {
      state.fetching += action.payload;
    },

    resetFetched(state) {
      state.fetched = 0;
    },

    incrementFetched(state, action: PayloadAction<number>) {
      state.fetched += action.payload;
    },

    resetBlocked(state) {
      state.blocked = [];
    },

    appendBlocked(state, action: PayloadAction<string>) {
      state.blocked = [...state.blocked, action.payload];
    },
  },
});

export const followingActions = following.actions;
export default following.reducer;
