import { rankingActions } from "../../module/ranking";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "../../asset/image/default_avatar.png";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import React, { useEffect, useState } from "react";
import "./ranking.css";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import { getAuth } from "firebase/auth";
import { RightSidebar } from "../right_sidebar";
import dot from "../../asset/image/dot.png";
import LinearProgress from "@mui/material/LinearProgress";
import { Loading } from "../loading/loading";
import { coreActions } from "../../module/core";
import gRanking from "../../asset/image/g_ranking.png";
import { UserState } from "../../module/user";
import underscore from "../../asset/image/underscore.png";
import horizontalLine from "../../asset/image/horizontal_line.png";

export function Ranking() {
  const isLoading = useAppSelector((state) => state.core.isLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(coreActions.setFocus("ranking"));
  }, []);

  return <div className="Ranking">{isLoading ? <Loading /> : <Body />}</div>;
}

function Body() {
  return (
    <div className="Ranking-Body">
      <Header />
      <Sidebar />
      <Title />
      <TabAndMain />
      <RightSidebar />
    </div>
  );
}

function Title() {
  return (
    <div className="Ranking-Title">
      <img src={gRanking} />
    </div>
  );
}

function LoadingBar() {
  return (
    <div className="Ranking-LoadingBar">
      <LinearProgress />
    </div>
  );
}

function TabAndMain() {
  return (
    <div className="Ranking-TabAndMain">
      <Tab />
      <Frequency />
      <MyRanking />
      <HorizontalLine />
      <Main />
    </div>
  );
}

function Frequency() {
  return (
    <div className="Ranking-Frequency">
      <img src={dot} />
      <p>Weekly ranking update</p>
    </div>
  );
}

function MyRanking() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/profile");
  };

  return (
    <div className="Ranking-MyRanking">
      <button onClick={onClick}>나의 랭킹 확인하기 ＞</button>
    </div>
  );
}

function Tab() {
  return (
    <div className="Ranking-Tab">
      <TabTotalRankings />
      <TabDocRankings />
      <TabCommentRankings />
    </div>
  );
}

function HorizontalLine() {
  return (
    <div className="Ranking-HorizontalLine">
      <img src={horizontalLine} />
    </div>
  );
}

function TabTotalRankings() {
  const tab = useAppSelector((state) => state.ranking.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(rankingActions.setTab("total"));
  };

  if (tab === "total") {
    return (
      <div className="Ranking-TabTotalRankings-Focus">
        <button onClick={onClick}>전체</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Ranking-TabTotalRankings">
        <button onClick={onClick}>전체</button>
      </div>
    );
  }
}

function TabDocRankings() {
  const tab = useAppSelector((state) => state.ranking.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(rankingActions.setTab("doc"));
  };

  if (tab === "doc") {
    return (
      <div className="Ranking-TabDocRankings-Focus">
        <button onClick={onClick}>글</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Ranking-TabDocRankings">
        <button onClick={onClick}>글</button>
      </div>
    );
  }
}

function TabCommentRankings() {
  const tab = useAppSelector((state) => state.ranking.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(rankingActions.setTab("comment"));
  };

  if (tab === "comment") {
    return (
      <div className="Ranking-TabCommentRankings-Focus">
        <button onClick={onClick}>댓글</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Ranking-TabCommentRankings">
        <button onClick={onClick}>댓글</button>
      </div>
    );
  }
}

function Main() {
  const tab = useAppSelector((state) => state.ranking.tab);

  const docRankingUsers = useAppSelector(
    (state) => state.ranking.docRankingUsers,
  );
  const commentRankingUsers = useAppSelector(
    (state) => state.ranking.commentRankingUsers,
  );
  const totalRankingUsers = useAppSelector(
    (state) => state.ranking.totalRankingUsers,
  );

  const docFetching = useAppSelector((state) => state.ranking.docFetching);
  const docFetched = useAppSelector((state) => state.ranking.docFetched);

  const commentFetching = useAppSelector(
    (state) => state.ranking.commentFetching,
  );
  const commentFetched = useAppSelector(
    (state) => state.ranking.commentFetched,
  );

  const totalFetching = useAppSelector((state) => state.ranking.totalFetching);
  const totalFetched = useAppSelector((state) => state.ranking.totalFetched);

  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const [docLastVisible, setDocLastVisible] = useState<any>(undefined);
  const [commentLastVisible, setCommentLastVisible] = useState<any>(undefined);
  const [totalLastVisible, setTotalLastVisible] = useState<any>(undefined);

  const getDocRankings = () => {
    const rankingQuery = query(
      collection(firestore, "ranking"),
      orderBy("timestamp", "desc"),
      limit(1),
    );

    onSnapshot(rankingQuery, async (snapshot) => {
      const newDocuments = snapshot.docs.map((elem) => elem.data());

      for (const elem of newDocuments) {
        let docRankingQuery;
        if (docLastVisible === -1) {
          return;
        } else if (docLastVisible !== undefined) {
          docRankingQuery = query(
            collection(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "docPoint",
            ),
            orderBy("docRanking", "asc"),
            limit(100),
            startAfter(docLastVisible),
          );
        } else {
          dispatch(rankingActions.resetDocRankingUsers());
          dispatch(rankingActions.resetDocFetching());
          dispatch(rankingActions.resetDocFetched());

          docRankingQuery = query(
            collection(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "docPoint",
            ),
            orderBy("docRanking", "asc"),
            limit(100),
          );
        }

        onSnapshot(docRankingQuery, async (snapshot) => {
          const newRankings = snapshot.docs.map((elem) => elem.data());

          dispatch(rankingActions.incrementDocFetching(newRankings.length));

          for (const elem2 of newRankings) {
            getDocRanking(elem.timestamp, elem2);
          }

          if (snapshot.docs.length === 0) {
            setDocLastVisible(-1);
          } else {
            setDocLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          }
        });
      }
    });
  };

  const getDocRanking = async (timestamp: number, elem: any) => {
    let avatarUrl = "";
    let name = "";
    let nickname = "";
    let isFollowing = false;
    let docPoint = 0;
    let docRanking = 0;
    let commentPoint = 0;
    let commentRanking = 0;
    let totalPoint = 0;
    let totalRanking = 0;

    const userDocRef = doc(firestore, "user", elem.uid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      avatarUrl = data.avatarUrl || "";
      name = data.name || "";
      nickname = data.nickname || "";
    } else {
      dispatch(rankingActions.incrementDocFetched(1));
      return;
    }

    if (auth.currentUser !== null) {
      const followingDocRef = doc(
        firestore,
        "user",
        auth.currentUser!.uid,
        "following",
        elem.uid,
      );

      await getDoc(followingDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          isFollowing = true;
        }
      });
    }

    const docRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "docPoint",
      elem.uid,
    );
    const commentRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "commentPoint",
      elem.uid,
    );
    const totalRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "totalPoint",
      elem.uid,
    );

    await getDoc(docRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("docPoint")) {
          docPoint = docSnap.data()["docPoint"];
        }

        if (docSnap.data().hasOwnProperty("docRanking")) {
          docRanking = docSnap.data()["docRanking"];
        }
      }
    });

    await getDoc(commentRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("commentPoint")) {
          commentPoint = docSnap.data()["commentPoint"];
        }

        if (docSnap.data().hasOwnProperty("commentRanking")) {
          commentRanking = docSnap.data()["commentRanking"];
        }
      }
    });

    await getDoc(totalRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("totalPoint")) {
          totalPoint = docSnap.data()["totalPoint"];
        }

        if (docSnap.data().hasOwnProperty("totalRanking")) {
          totalRanking = docSnap.data()["totalRanking"];
        }
      }
    });

    const user: UserState = {
      uid: elem.uid,
      timestamp: elem.timestamp,
      avatarUrl: avatarUrl,
      name: name,
      nickname: nickname,
      isFollowing: isFollowing,
      docPoint: docPoint,
      docRanking: docRanking,
      commentPoint: commentPoint,
      commentRanking: commentRanking,
      totalPoint: totalPoint,
      totalRanking: totalRanking,
    };

    dispatch(rankingActions.appendDocRankingUser(user));
    dispatch(rankingActions.incrementDocFetched(1));
  };

  const getCommentRankings = () => {
    const rankingQuery = query(
      collection(firestore, "ranking"),
      orderBy("timestamp", "desc"),
      limit(1),
    );

    onSnapshot(rankingQuery, async (snapshot) => {
      const newDocuments = snapshot.docs.map((elem) => elem.data());

      for (const elem of newDocuments) {
        let commentRankingQuery;
        if (commentLastVisible === -1) {
          return;
        } else if (commentLastVisible !== undefined) {
          commentRankingQuery = query(
            collection(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "commentPoint",
            ),
            orderBy("commentRanking", "asc"),
            limit(100),
            startAfter(commentLastVisible),
          );
        } else {
          dispatch(rankingActions.resetCommentRankingUsers());
          dispatch(rankingActions.resetCommentFetching());
          dispatch(rankingActions.resetCommentFetched());

          commentRankingQuery = query(
            collection(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "commentPoint",
            ),
            orderBy("commentRanking", "asc"),
            limit(100),
          );
        }

        onSnapshot(commentRankingQuery, async (snapshot) => {
          const newRankings = snapshot.docs.map((elem) => elem.data());

          dispatch(rankingActions.incrementCommentFetching(newRankings.length));

          for (const elem2 of newRankings) {
            getCommentRanking(elem.timestamp, elem2);
          }

          if (snapshot.docs.length === 0) {
            setCommentLastVisible(-1);
          } else {
            setCommentLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          }
        });
      }
    });
  };

  const getCommentRanking = async (timestamp: number, elem: any) => {
    let avatarUrl = "";
    let name = "";
    let nickname = "";
    let isFollowing = false;
    let docPoint = 0;
    let docRanking = 0;
    let commentPoint = 0;
    let commentRanking = 0;
    let totalPoint = 0;
    let totalRanking = 0;

    const userDocRef = doc(firestore, "user", elem.uid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      avatarUrl = data.avatarUrl || "";
      name = data.name || "";
      nickname = data.nickname || "";
    } else {
      dispatch(rankingActions.incrementCommentFetched(1));
      return;
    }

    if (auth.currentUser !== null) {
      const followingDocRef = doc(
        firestore,
        "user",
        auth.currentUser!.uid,
        "following",
        elem.uid,
      );

      await getDoc(followingDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          isFollowing = true;
        }
      });
    }

    const docRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "docPoint",
      elem.uid,
    );
    const commentRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "commentPoint",
      elem.uid,
    );
    const totalRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "totalPoint",
      elem.uid,
    );

    await getDoc(docRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("docPoint")) {
          docPoint = docSnap.data()["docPoint"];
        }

        if (docSnap.data().hasOwnProperty("docRanking")) {
          docRanking = docSnap.data()["docRanking"];
        }
      }
    });

    await getDoc(commentRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("commentPoint")) {
          commentPoint = docSnap.data()["commentPoint"];
        }

        if (docSnap.data().hasOwnProperty("commentRanking")) {
          commentRanking = docSnap.data()["commentRanking"];
        }
      }
    });

    await getDoc(totalRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("totalPoint")) {
          totalPoint = docSnap.data()["totalPoint"];
        }

        if (docSnap.data().hasOwnProperty("totalRanking")) {
          totalRanking = docSnap.data()["totalRanking"];
        }
      }
    });

    const user: UserState = {
      uid: elem.uid,
      timestamp: elem.timestamp,
      avatarUrl: avatarUrl,
      name: name,
      nickname: nickname,
      isFollowing: isFollowing,
      docPoint: docPoint,
      docRanking: docRanking,
      commentPoint: commentPoint,
      commentRanking: commentRanking,
      totalPoint: totalPoint,
      totalRanking: totalRanking,
    };

    dispatch(rankingActions.appendCommentRankingUser(user));
    dispatch(rankingActions.incrementCommentFetched(1));
  };

  const getTotalRankings = () => {
    const rankingQuery = query(
      collection(firestore, "ranking"),
      orderBy("timestamp", "desc"),
      limit(1),
    );

    onSnapshot(rankingQuery, async (snapshot) => {
      const newDocuments = snapshot.docs.map((elem) => elem.data());

      for (const elem of newDocuments) {
        let totalRankingQuery;
        if (totalLastVisible === -1) {
          return;
        } else if (totalLastVisible !== undefined) {
          totalRankingQuery = query(
            collection(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "totalPoint",
            ),
            orderBy("totalRanking", "asc"),
            limit(100),
            startAfter(totalLastVisible),
          );
        } else {
          dispatch(rankingActions.resetTotalRankingUsers());
          dispatch(rankingActions.resetTotalFetching());
          dispatch(rankingActions.resetTotalFetched());

          totalRankingQuery = query(
            collection(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "totalPoint",
            ),
            orderBy("totalRanking", "asc"),
            limit(100),
          );
        }

        onSnapshot(totalRankingQuery, async (snapshot) => {
          const newRankings = snapshot.docs.map((elem) => elem.data());

          dispatch(rankingActions.incrementTotalFetching(newRankings.length));

          for (const elem2 of newRankings) {
            getTotalRanking(elem.timestamp, elem2);
          }

          if (snapshot.docs.length === 0) {
            setTotalLastVisible(-1);
          } else {
            setTotalLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          }
        });
      }
    });
  };

  const getTotalRanking = async (timestamp: number, elem: any) => {
    let avatarUrl = "";
    let name = "";
    let nickname = "";
    let isFollowing = false;
    let docPoint = 0;
    let docRanking = 0;
    let commentPoint = 0;
    let commentRanking = 0;
    let totalPoint = 0;
    let totalRanking = 0;

    const userDocRef = doc(firestore, "user", elem.uid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      avatarUrl = data.avatarUrl || "";
      name = data.name || "";
      nickname = data.nickname || "";
    } else {
      dispatch(rankingActions.incrementTotalFetched(1));
      return;
    }

    if (auth.currentUser !== null) {
      const followingDocRef = doc(
        firestore,
        "user",
        auth.currentUser!.uid,
        "following",
        elem.uid,
      );

      await getDoc(followingDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          isFollowing = true;
        }
      });
    }

    const docRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "docPoint",
      elem.uid,
    );
    const commentRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "commentPoint",
      elem.uid,
    );
    const totalRankingDocRef = doc(
      firestore,
      "ranking",
      timestamp.toString(),
      "totalPoint",
      elem.uid,
    );

    await getDoc(docRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("docPoint")) {
          docPoint = docSnap.data()["docPoint"];
        }

        if (docSnap.data().hasOwnProperty("docRanking")) {
          docRanking = docSnap.data()["docRanking"];
        }
      }
    });

    await getDoc(commentRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("commentPoint")) {
          commentPoint = docSnap.data()["commentPoint"];
        }

        if (docSnap.data().hasOwnProperty("commentRanking")) {
          commentRanking = docSnap.data()["commentRanking"];
        }
      }
    });

    await getDoc(totalRankingDocRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().hasOwnProperty("totalPoint")) {
          totalPoint = docSnap.data()["totalPoint"];
        }

        if (docSnap.data().hasOwnProperty("totalRanking")) {
          totalRanking = docSnap.data()["totalRanking"];
        }
      }
    });

    const user: UserState = {
      uid: elem.uid,
      timestamp: elem.timestamp,
      avatarUrl: avatarUrl,
      name: name,
      nickname: nickname,
      isFollowing: isFollowing,
      docPoint: docPoint,
      docRanking: docRanking,
      commentPoint: commentPoint,
      commentRanking: commentRanking,
      totalPoint: totalPoint,
      totalRanking: totalRanking,
    };

    dispatch(rankingActions.appendTotalRankingUser(user));
    dispatch(rankingActions.incrementTotalFetched(1));
  };

  const getItems = () => {
    if (tab === "total") {
      getTotalRankings();
    } else if (tab === "doc") {
      getDocRankings();
    } else if (tab === "comment") {
      getCommentRankings();
    }
  };

  useEffect(() => {
    getItems();
  }, [tab]);

  useEffect(() => {
    dispatch(rankingActions.resetDocRankingUsers());
    dispatch(rankingActions.resetCommentRankingUsers());
    dispatch(rankingActions.resetTotalRankingUsers());
  }, []);

  useBottomScrollListener(getItems);

  if (tab === "total") {
    if (totalFetched < totalFetching) {
      return (
        <div className="Ranking-Main">
          <LoadingBar />
          <TotalRankings />
        </div>
      );
    } else {
      if (totalRankingUsers.length > 0) {
        return (
          <div className="Ranking-Main">
            <TotalRankings />
          </div>
        );
      } else {
        return (
          <div className="Ranking-TotalRankings-Void">
            <p>랭킹이 없습니다.</p>
          </div>
        );
      }
    }
  } else if (tab === "doc") {
    if (docFetched < docFetching) {
      return (
        <div className="Ranking-Main">
          <LoadingBar />
          <DocRankings />
        </div>
      );
    } else {
      if (docRankingUsers.length > 0) {
        return (
          <div className="Ranking-Main">
            <DocRankings />
          </div>
        );
      } else {
        return (
          <div className="Ranking-DocRankings-Void">
            <p>랭킹이 없습니다.</p>
          </div>
        );
      }
    }
  } else {
    if (commentFetched < commentFetching) {
      return (
        <div className="Ranking-Main">
          <LoadingBar />
          <CommentRankings />
        </div>
      );
    } else {
      if (commentRankingUsers.length > 0) {
        return (
          <div className="Ranking-Main">
            <CommentRankings />
          </div>
        );
      } else {
        return (
          <div className="Ranking-CommentRankings-Void">
            <p>랭킹이 없습니다.</p>
          </div>
        );
      }
    }
  }
}

function TotalRankings() {
  const totalRankingUsers = useAppSelector(
    (state) => state.ranking.totalRankingUsers,
  );

  return (
    <div className="Ranking-TotalRankings">
      {totalRankingUsers.map((item, _) => {
        return <TotalRanking user={item} />;
      })}
    </div>
  );
}

function TotalRanking(props: { user: UserState }) {
  return (
    <div className="Ranking-TotalRanking">
      <UserHeader user={props.user} />
    </div>
  );
}

function DocRankings() {
  const docRankingUsers = useAppSelector(
    (state) => state.ranking.docRankingUsers,
  );

  return (
    <div className="Ranking-TotalRankings">
      {docRankingUsers.map((item, _) => {
        return <DocRanking user={item} />;
      })}
    </div>
  );
}

function DocRanking(props: { user: UserState }) {
  return (
    <div className="Ranking-DocRanking">
      <UserHeader user={props.user} />
    </div>
  );
}

function CommentRankings() {
  const commentRankingUsers = useAppSelector(
    (state) => state.ranking.commentRankingUsers,
  );

  return (
    <div className="Ranking-CommentRankings">
      {commentRankingUsers.map((item, _) => {
        return <CommentRanking user={item} />;
      })}
    </div>
  );
}

function CommentRanking(props: { user: UserState }) {
  return (
    <div className="Ranking-CommentRanking">
      <UserHeader user={props.user} />
    </div>
  );
}

function UserHeader(props: { user: UserState }) {
  return (
    <div className="Ranking-UserHeader">
      <RankingNumber user={props.user} />
      <UserAvatar user={props.user} />
      <UserNames user={props.user} />
      <Detail user={props.user} />
      <UserFollowOrUnfollow user={props.user} />
    </div>
  );
}

function RankingNumber(props: { user: UserState }) {
  const tab = useAppSelector((state) => state.ranking.tab);

  if (tab == "total") {
    return (
      <div className="Ranking-RankingNumber">
        <h1>{props.user.totalRanking}위</h1>
      </div>
    );
  } else if (tab == "doc") {
    return (
      <div className="Ranking-RankingNumber">
        <h1>{props.user.docRanking}위</h1>
      </div>
    );
  } else {
    return (
      <div className="Ranking-RankingNumber">
        <h1>{props.user.commentRanking}위</h1>
      </div>
    );
  }
}

function UserAvatar(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.avatarUrl !== "") {
    return (
      <div className="Ranking-UserAvatar">
        <img src={props.user.avatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Ranking-UserAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function UserNames(props: { user: UserState }) {
  return (
    <div className="Ranking-UserNames">
      <UserName user={props.user} />
      <UserNickname user={props.user} />
    </div>
  );
}

function UserName(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.name !== "") {
    return (
      <div className="Ranking-UserName">
        <button onClick={onClick}>{props.user.name}</button>
      </div>
    );
  } else {
    return (
      <div className="Ranking-UserName">
        <button onClick={onClick}>이름 없음</button>
      </div>
    );
  }
}

function UserNickname(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.nickname !== "") {
    return (
      <div className="Ranking-UserNickname">
        <button onClick={onClick}>{props.user.nickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Ranking-UserNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function UserFollowOrUnfollow(props: { user: UserState }) {
  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    dispatch(coreActions.setIsLoading(true));

    if (props.user.uid === auth.currentUser!.uid) {
      alert("나 자신을 팔로우 할 수 없습니다");
    } else {
      if (props.user.isFollowing) {
        const followingRef = doc(
          firestore,
          "user",
          auth.currentUser!.uid,
          "following",
          props.user.uid,
        );

        const followerRef = doc(
          firestore,
          "user",
          props.user.uid,
          "follower",
          auth.currentUser!.uid,
        );

        await deleteDoc(followingRef);
        await deleteDoc(followerRef);
      } else {
        const followingRef = doc(
          firestore,
          "user",
          auth.currentUser!.uid,
          "following",
          props.user.uid,
        );

        const followerRef = doc(
          firestore,
          "user",
          props.user.uid,
          "follower",
          auth.currentUser!.uid,
        );

        const timestamp = Date.now();

        await setDoc(followingRef, {
          uid: props.user.uid,
          timestamp: timestamp,
        });

        await setDoc(followerRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });
      }
    }

    dispatch(coreActions.setIsLoading(false));
  };

  if (props.user.isFollowing) {
    return (
      <div className="Ranking-UserFollowOrUnfollow">
        <button onClick={onClick}>팔로잉 중단</button>
      </div>
    );
  } else {
    return (
      <div className="Ranking-UserFollowOrUnfollow">
        <button onClick={onClick}>팔로잉</button>
      </div>
    );
  }
}

function Detail(props: { user: UserState }) {
  return (
    <div className="Ranking-Detail">
      <TotalDetail user={props.user} />
      <DocDetail user={props.user} />
      <CommentDetail user={props.user} />
    </div>
  );
}

function DocDetail(props: { user: UserState }) {
  if (props.user.docRanking !== 0) {
    return (
      <div className="Ranking-DocDetail">
        <h1>글</h1>
        <p>
          {props.user.docRanking}위 ({props.user.docPoint} P)
        </p>
      </div>
    );
  } else {
    return (
      <div className="Ranking-DocDetail">
        <h1>글</h1>
        <p>-</p>
      </div>
    );
  }
}

function CommentDetail(props: { user: UserState }) {
  if (props.user.commentRanking !== 0) {
    return (
      <div className="Ranking-CommentDetail">
        <h1>댓글</h1>
        <p>
          {props.user.commentRanking}위 ({props.user.commentPoint} P)
        </p>
      </div>
    );
  } else {
    return (
      <div className="Ranking-CommentDetail">
        <h1>댓글</h1>
        <p>-</p>
      </div>
    );
  }
}

function TotalDetail(props: { user: UserState }) {
  if (props.user.totalRanking !== 0) {
    return (
      <div className="Ranking-TotalDetail">
        <h1>전체</h1>
        <p>
          {props.user.totalRanking}위 ({props.user.totalPoint} P)
        </p>
      </div>
    );
  } else {
    return (
      <div className="Ranking-TotalDetail">
        <h1>전체</h1>
        <p>-</p>
      </div>
    );
  }
}
