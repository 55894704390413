import "./mobile_password_reset.css";
import logoTransparent from "../../asset/image/logo_transparent.png";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import { MobileLoading } from "../loading/mobile_loading";
import { MobileHeader } from "../mobile_header";
import { passwordResetActions } from "../../module/password_reset";
import { coreActions } from "../../module/core";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export function MobilePasswordReset() {
  const isLoading = useAppSelector((state) => state.core.isLoading);

  return (
    <div className="MobilePasswordReset">
      {isLoading ? <MobileLoading /> : <Body />}
    </div>
  );
}

function Body() {
  return (
    <div className={"MobilePasswordReset-Body"}>
      <MobileHeader />
      <Title />
      <Background />
      <Inputs />
      <Continue />
    </div>
  );
}

function Title() {
  return (
    <div className="MobilePasswordReset-Title">
      <h1>비밀번호 찾기</h1>
      <p>이메일 주소를 입력하시면 비밀번호 재설정 링크를 보내드립니다</p>
    </div>
  );
}

function Background() {
  return (
    <div className="MobilePasswordReset-Background">
      <img src={logoTransparent} />
    </div>
  );
}

function Inputs() {
  return (
    <div className="MobilePasswordReset-Inputs">
      <EmailInput />
    </div>
  );
}

function EmailInput() {
  return (
    <div className="MobilePasswordReset-EmailInput">
      <EmailInputHint />
      <EmailInputForm />
    </div>
  );
}

function EmailInputHint() {
  return (
    <div className="MobilePasswordReset-EmailInputHint">
      <h1>이메일 주소</h1>
      <p>*</p>
    </div>
  );
}

function EmailInputForm() {
  const email = useAppSelector((state) => state.passwordReset.email);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(passwordResetActions.setEmail(e.target.value));
  };

  return (
    <div className="MobilePasswordReset-EmailInputForm">
      <form>
        <input
          type="text"
          placeholder="이메일 주소"
          required
          value={email}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function Continue() {
  const email = useAppSelector((state) => state.passwordReset.email);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();

  const onClick = async () => {
    if (email !== "") {
      dispatch(coreActions.setIsLoading(true));

      sendPasswordResetEmail(auth, email)
        .then(() => {
          dispatch(coreActions.setIsLoading(false));

          alert("비밀번호 재설정 이메일이 전송되었습니다.");

          navigate("/");
        })
        .catch((error) => {
          const errorCode = error.code;

          if (errorCode === "auth/missing-email") {
            alert("이메일 주소가 올바르지 않습니다.");
          } else {
            alert("서버와의 통신에 실패하였습니다.");
          }

          dispatch(coreActions.setIsLoading(false));
        });
    } else {
      alert("이메일 주소를 입력해주세요");
    }
  };

  if (email !== "") {
    return (
      <div className="MobilePasswordReset-ContinueActive">
        <button onClick={onClick}>링크 요청</button>
      </div>
    );
  } else {
    return (
      <div className="MobilePasswordReset-ContinueInactive">
        <button onClick={onClick}>링크 요청</button>
      </div>
    );
  }
}
