import { coreActions } from "../../module/core";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { MobileLoading } from "../loading/mobile_loading";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import "./mobile_write.css";
import { MobileHeader } from "../mobile_header";
import { MobileSidebar } from "../mobile_sidebar";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import ReactQuill from "react-quill";
import { writeActions } from "../../module/write";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import uuid from "react-uuid";
import hashtag from "../../asset/image/hashtag.png";
import LinearProgress from "@mui/material/LinearProgress";
import { DocumentState } from "../../module/document";

export function MobileWrite() {
  const navigate = useNavigate();

  const isLoading = useAppSelector((state) => state.core.isLoading);

  const dispatch = useAppDispatch();

  const auth = getAuth();

  useEffect(() => {
    if (auth.currentUser === null) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
        } else {
          navigate("/login");
        }
      });
    } else {
      dispatch(coreActions.setFocus("write"));

      dispatch(writeActions.setIsImageLoading(false));
      dispatch(writeActions.setContent(""));
      dispatch(writeActions.setHashtags([]));
    }
  }, []);

  return (
    <div className="MobileWrite">
      {isLoading ? <MobileLoading /> : <Body />}
    </div>
  );
}

function Body() {
  const quillRef = useRef<ReactQuill>(null);

  const content = useAppSelector((state) => state.write.content);

  const dispatch = useAppDispatch();

  const imageHandler = () => {
    const editor = quillRef.current!.getEditor();

    const storage = getStorage();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click();

    input.addEventListener("change", async () => {
      const range = editor.getSelection(true);
      try {
        if (input.files && range) {
          dispatch(writeActions.setIsImageLoading(true));

          const uploadPromises = Array.from(input.files).map(async (file) => {
            const fileId = `${Date.now()}-${uuid()}`;
            const storageRef = ref(storage, `image/${fileId}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);

            editor.insertEmbed(range.index, "image", url);
            editor.setSelection(range.index + 1, 0);
          });
          await Promise.all(uploadPromises);

          dispatch(writeActions.setIsImageLoading(false));
        }
      } catch (error) {
        const auth = getAuth();

        if (auth.currentUser) {
          alert("서버와의 통신에 실패하였습니다.");
        } else {
          alert("글 쓰기는 로그인하여야 가능합니다.");
        }

        dispatch(writeActions.setIsImageLoading(false));
      }
    });

    editor.on("text-change", async (_, oldDelta, source) => {
      if (source !== "user") {
        return;
      }

      const imageUrls = editor
        .getContents()
        .diff(oldDelta)
        .ops?.filter((i) => i.insert && i.insert.image)
        .map((i) => i.insert.image);

      for (let i = 0; i < imageUrls!.length; i++) {
        const imageUrl = imageUrls![i];

        const baseUrl =
          "https://firebasestorage.googleapis.com/v0/b/giggles-62f17.appspot.com/o/";

        let imagePath: string = imageUrl.replace(baseUrl, "");

        const indexOfEndPath = imagePath.indexOf("?");

        imagePath = imagePath.substring(0, indexOfEndPath);

        imagePath = imagePath.replace("%2F", "/");

        const storageRef = ref(storage, imagePath);

        /*
                        getDownloadURL(storageRef)
                          .then((_) => {
                            deleteObject(storageRef);
                          })
                          .catch((_) => {});
                        */
      }
    });
  };

  const toolbarOptions = useMemo(
    () => ({
      toolbar: {
        container: [[], [{}], ["image", "video", "link"]],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  );

  return (
    <div className="MobileWrite-Body">
      <MobileHeader />
      <MobileSidebar />
      <Title />
      <HashtagInput />
      <Hashtags />
      <ReactQuill
        className="MobileWrite-Quill"
        ref={quillRef}
        modules={toolbarOptions}
        value={content}
        onChange={(content) => dispatch(writeActions.setContent(content))}
      />
      <Submit />
    </div>
  );
}

function Title() {
  const isImageLoading = useAppSelector((state) => state.write.isImageLoading);

  if (isImageLoading) {
    return (
      <div className="MobileWrite-Title">
        <h1>게시글 작성</h1>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <div className="MobileWrite-Title">
        <h1>게시글 작성</h1>
      </div>
    );
  }
}

function LoadingBar() {
  return (
    <div className="MobileWrite-LoadingBar">
      <LinearProgress />
    </div>
  );
}

function HashtagInput() {
  const hashtags = useAppSelector((state) => state.write.hashtags);

  const dispatch = useAppDispatch();

  const [content, setContent] = useState("");

  const onChange = (event: any) => {
    setContent(event.target.value);
  };

  const onClick = () => {
    if (content !== "" && !hashtags.includes(content)) {
      if (hashtags.length > 2) {
        alert("해시태그는 최대 3개까지만 지정할 수 있습니다.");

        setContent("");
      } else {
        if (content[0] !== "#") {
          if (content.length < 10) {
            dispatch(writeActions.setHashtags([...hashtags, "#" + content]));
            setContent("");
          } else {
            alert("해시태그는 최대 10자까지 입력할 수 있습니다.");
          }
        } else {
          if (content.length < 11) {
            dispatch(writeActions.setHashtags([...hashtags, content]));
            setContent("");
          } else {
            alert("해시태그는 최대 10자까지 입력할 수 있습니다.");
          }
        }
      }
    }
  };

  return (
    <div className="MobileWrite-HashtagInput">
      <img src={hashtag} />
      {
        <input
          type="text"
          onChange={(e) => onChange(e)}
          placeholder="태그입력(10자 미만, 최대 3개)"
          value={content}
        />
      }
      <button onClick={onClick}>추가</button>
    </div>
  );
}

function Hashtags() {
  const hashtags = useAppSelector((state) => state.write.hashtags);

  const dispatch = useAppDispatch();

  const removeHashtag = (indexToRemove: any) => {
    const filter = hashtags.filter((_, index) => index !== indexToRemove);
    dispatch(writeActions.setHashtags(filter));
  };

  return (
    <div className="MobileWrite-Hashtags">
      {
        <ul>
          {hashtags.map((hashtag, index) => (
            <li key={index}>
              <span
                className="MobileWrite-Hashtags-tag"
                onClick={() => removeHashtag(index)}
              >
                {hashtag}
              </span>
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Submit() {
  const navigate = useNavigate();

  const content = useAppSelector((state) => state.write.content);
  const hashtags = useAppSelector((state) => state.write.hashtags);

  const dispatch = useAppDispatch();

  const onClick = async () => {
    dispatch(coreActions.setIsLoading(true));

    const auth = getAuth();
    const firestore = getFirestore();

    let authorAvatarUrl = "";
    let authorNickname = "";
    let authorName = "";

    const userDocRef = doc(firestore, "user", auth.currentUser!.uid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      authorAvatarUrl = data.avatarUrl || "";
      authorNickname = data.nickname || "";
      authorName = data.name || "";
    } else {
      alert(
        "회원가입이 완료되지 않았습니다. 프로필에서 아이디와 닉네임을 설정해주세요.",
      );
      navigate("/profile");
      dispatch(coreActions.setIsLoading(false));
      return;
    }

    if (authorNickname === "" || authorName === "") {
      alert(
        "회원가입이 완료되지 않았습니다. 프로필에서 아이디와 닉네임을 설정해주세요.",
      );
      navigate("/profile");
      dispatch(coreActions.setIsLoading(false));
      return;
    }

    const timestamp = Date.now();

    const id = uuid();

    const documentId = timestamp.toString() + "-" + id;

    await setDoc(
      doc(firestore, "user", auth.currentUser!.uid, "post", documentId),
      {
        documentId: documentId,
        timestamp: timestamp,
      },
    );

    await setDoc(doc(firestore, "board", documentId), {
      documentId: documentId,
      authorUid: auth.currentUser!.uid,
      content: content,
      timestamp: timestamp,
      hashtags: hashtags.join(","),
    });

    await setDoc(doc(firestore, "metadata", documentId), {
      documentId: documentId,
      authorUid: auth.currentUser!.uid,
      timestamp: timestamp,
    });

    for (const hashtag of hashtags) {
      await setDoc(doc(firestore, "hashtag", hashtag, "post", documentId), {
        documentId: documentId,
        hashtag: hashtag,
        timestamp: timestamp,
      });
    }

    const d: DocumentState = {
      documentId: documentId,
      authorUid: auth.currentUser!.uid,
      authorAvatarUrl: authorAvatarUrl,
      authorNickname: authorNickname,
      timestamp: timestamp,
      content: content,
      thumbnailContent: [],
      thumbnailImageSizes: [],
      hashtags: hashtags,
      numUps: 0,
      numDowns: 0,
      numComments: 0,
      numTokens: 0,
      clickUp: false,
      clickDown: false,
      clickBookmark: false,
    };

    navigate(`/read/${documentId}`, {
      state: {
        document: d,
      },
    });
    navigate(0);

    dispatch(coreActions.setIsLoading(false));
  };

  return (
    <div className="MobileWrite-Submit">
      <button onClick={onClick}>글 쓰기</button>
    </div>
  );
}
