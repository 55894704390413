import { DocumentState } from "../../module/document";
import { noticeActions } from "../../module/notice";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "../../asset/image/default_avatar.png";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import React, { useEffect, useState } from "react";
import "./mobile_notice.css";
import { MobileHeader } from "../mobile_header";
import { MobileSidebar } from "../mobile_sidebar";
import moment from "moment";
import parse from "html-react-parser";
import { getAuth } from "firebase/auth";
import LinearProgress from "@mui/material/LinearProgress";
import { MobileLoading } from "../loading/mobile_loading";
import { coreActions } from "../../module/core";
import trash from "../../asset/image/trash.png";
import { PenNib } from "@phosphor-icons/react";

export function MobileNotice() {
  const auth = getAuth();
  const firestore = getFirestore();

  const isLoading = useAppSelector((state) => state.core.isLoading);
  const documents = useAppSelector((state) => state.notice.documents);

  const dispatch = useAppDispatch();

  const [images, setImages] = useState(new Map());
  const [timestamp, setTimestamp] = useState(Date.now());
  const [lastVisible, setLastVisible] = useState<any>(undefined);

  const getDocuments = async () => {
    let q;
    if (lastVisible === -1) {
      return;
    } else if (lastVisible !== undefined) {
      q = query(
        collection(firestore, "notice"),
        orderBy("timestamp", "desc"),
        limit(10),
        startAfter(lastVisible),
      );
    } else {
      dispatch(noticeActions.resetFetching());
      dispatch(noticeActions.resetFetched());

      q = query(
        collection(firestore, "notice"),
        orderBy("timestamp", "desc"),
        limit(10),
      );
    }

    onSnapshot(q, async (snapshot) => {
      const newDocuments = snapshot.docs.map((elem) => elem.data());

      dispatch(noticeActions.incrementFetching(newDocuments.length));

      for (const elem of newDocuments) {
        getDocument(elem);
      }

      if (snapshot.docs.length === 0) {
        setLastVisible(-1);
      } else {
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }
    });
  };

  const getDocument = async (elem: any) => {
    let authorAvatarUrl = "";
    let authorNickname = "";

    const userDocRef = doc(firestore, "user", elem.authorUid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      authorAvatarUrl = data.avatarUrl || "";
      authorNickname = data.nickname || "";
    } else {
      dispatch(noticeActions.appendBlocked(elem.documentId));
      dispatch(noticeActions.incrementFetched(1));
      return;
    }

    const document: DocumentState = {
      documentId: elem.documentId,
      authorUid: elem.authorUid,
      authorAvatarUrl: authorAvatarUrl,
      authorNickname: authorNickname,
      timestamp: elem.timestamp,
      content: elem.content,
      thumbnailContent: [],
      thumbnailImageSizes: [],
      hashtags: [],
      numUps: 0,
      numDowns: 0,
      numComments: 0,
      numTokens: 0,
      clickUp: false,
      clickDown: false,
      clickBookmark: false,
    };

    if (elem.timestamp < timestamp) {
      dispatch(noticeActions.appendDocument(document));
    }

    dispatch(noticeActions.incrementFetched(1));
  };

  useEffect(() => {
    dispatch(coreActions.setFocus("notice"));

    dispatch(noticeActions.resetBlocked());
    dispatch(noticeActions.resetDocuments());

    setLastVisible(undefined);

    getDocuments();
  }, []);

  useBottomScrollListener(getDocuments);

  return (
    <div className="Notice">
      {isLoading ? <MobileLoading /> : <Body documents={documents} />}
    </div>
  );
}

function Body(props: { documents: DocumentState[] }) {
  return (
    <div className="MobileNotice-Body">
      <MobileHeader />
      <MobileSidebar />
      <Main documents={props.documents} />
    </div>
  );
}

function Main(props: { documents: DocumentState[] }) {
  const fetching = useAppSelector((state) => state.notice.fetching);
  const fetched = useAppSelector((state) => state.notice.fetched);

  if (fetched < fetching) {
    return (
      <div className="MobileNotice-Main">
        <Title />
        <LoadingBar />
        <Documents documents={props.documents} />
      </div>
    );
  } else {
    return (
      <div className="MobileNotice-Main">
        <Title />
        <Documents documents={props.documents} />
      </div>
    );
  }
}

function Title() {
  const isAdmin = useAppSelector((state) => state.core.isAdmin);

  if (isAdmin) {
    return (
      <div className="MobileNotice-Title">
        <h1>공지사항</h1>
        <p>문의사항은 admin@giggles.expert으로 이메일을 보내주세요.</p>
        <Write />
      </div>
    );
  } else {
    return (
      <div className="MobileNotice-Title">
        <h1>공지사항</h1>
        <p>문의사항은 admin@giggles.expert으로 이메일을 보내주세요.</p>
      </div>
    );
  }
}

function Write() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/notice_write");
  };

  return (
    <div className="MobileNotice-Write">
      <button onClick={onClick}>
        <PenNib size={24} />
      </button>
    </div>
  );
}

function LoadingBar() {
  return (
    <div className="MobileNotice-LoadingBar">
      <LinearProgress />
    </div>
  );
}

function Documents(props: { documents: DocumentState[] }) {
  const documents = props.documents.map((item, _) => {
    return <Document document={item} />;
  });

  return <div className="MobileNotice-Documents">{documents}</div>;
}

function Document(props: { document: DocumentState }) {
  return (
    <div className="MobileNotice-Document">
      <DocumentHeader document={props.document} />
      <Content document={props.document} />
    </div>
  );
}

function DocumentHeader(props: { document: DocumentState }) {
  const isAdmin = useAppSelector((state) => state.core.isAdmin);

  if (isAdmin) {
    return (
      <div className="MobileNotice-DocumentHeader">
        <Avatar document={props.document} />
        <Nickname document={props.document} />
        <Time document={props.document} />
        <Delete document={props.document} />
      </div>
    );
  } else {
    return (
      <div className="MobileNotice-DocumentHeader">
        <Avatar document={props.document} />
        <Nickname document={props.document} />
        <Time document={props.document} />
      </div>
    );
  }
}

function Avatar(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorAvatarUrl !== "") {
    return (
      <div className="MobileNotice-Avatar">
        <img src={props.document.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="MobileNotice-Avatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function Nickname(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorNickname !== "") {
    return (
      <div className="MobileNotice-Nickname">
        <button onClick={onClick}>{props.document.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="MobileNotice-Nickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function Time(props: { document: DocumentState }) {
  const endTimestamp = Date.now();

  const diff = endTimestamp - props.document.timestamp;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) {
    return (
      <div className="MobileNotice-Time">
        <p>방금 전</p>
      </div>
    );
  } else if (hours < 1) {
    return (
      <div className="MobileNotice-Time">
        <p>{minutes} 분 전</p>
      </div>
    );
  } else if (days < 1) {
    return (
      <div className="MobileNotice-Time">
        <p>{hours} 시간 전</p>
      </div>
    );
  } else {
    const date = moment(props.document.timestamp);

    return (
      <div className="MobileNotice-Time">
        <p>{date.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function Delete(props: { document: DocumentState }) {
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const onClick = async () => {
    const answer = window.confirm("정말 삭제하시겠습니까?");

    if (answer) {
      dispatch(coreActions.setIsLoading(true));

      await deleteDoc(doc(firestore, "notice", props.document.documentId));

      dispatch(noticeActions.removeDocument(props.document));

      dispatch(coreActions.setIsLoading(false));
    }
  };

  return (
    <div className="MobileNotice-Delete">
      <img onClick={onClick} src={trash} />
    </div>
  );
}

function Content(props: { document: DocumentState }) {
  const parsedContent = parse(props.document.content);

  return <div className="MobileNotice-Content">{parsedContent}</div>;
}
