import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";
import { CommentState } from "./comment";
import { ReplyState } from "./reply";

export interface ReadState {
  document: DocumentState;
  bestComments: CommentState[];
  comments: CommentState[];
  commentsFetching: number;
  commentsFetched: number;
  commentsBlocked: string[];
  documents: DocumentState[];
  clickedComment: string | null;
  replies: ReplyState[];
  repliesFetching: number;
  repliesFetched: number;
  repliesBlocked: string[];
  documentsMaxFetching: number;
  documentsFetching: number;
  documentsFetched: number;
  documentsBlocked: string[];
}

const initialState: ReadState = {
  document: {
    documentId: "",
    authorUid: "",
    authorAvatarUrl: "",
    authorNickname: "",
    timestamp: 0,
    content: "",
    thumbnailContent: [],
    thumbnailImageSizes: [],
    hashtags: [],
    numUps: 0,
    numDowns: 0,
    numComments: 0,
    numTokens: 0,
    clickUp: false,
    clickDown: false,
    clickBookmark: false,
  },
  bestComments: [],
  comments: [],
  commentsFetching: 0,
  commentsFetched: 0,
  commentsBlocked: [],
  documents: [],
  clickedComment: null,
  replies: [],
  repliesFetching: 0,
  repliesFetched: 0,
  repliesBlocked: [],
  documentsMaxFetching: 0,
  documentsFetching: 0,
  documentsFetched: 0,
  documentsBlocked: [],
};

export const read = createSlice({
  name: "read",
  initialState,
  reducers: {
    resetDocument(state) {
      state.document = {
        documentId: "",
        authorUid: "",
        authorAvatarUrl: "",
        authorNickname: "",
        timestamp: 0,
        content: "",
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: [],
        numUps: 0,
        numDowns: 0,
        numComments: 0,
        numTokens: 0,
        clickUp: false,
        clickDown: false,
        clickBookmark: false,
      };
    },

    setDocument(state, action: PayloadAction<DocumentState>) {
      state.document = action.payload;
    },

    setNumUps(state, action: PayloadAction<number>) {
      state.document.numUps = action.payload;
    },

    setNumDowns(state, action: PayloadAction<number>) {
      state.document.numDowns = action.payload;
    },

    setNumComments(state, action: PayloadAction<number>) {
      state.document.numComments = action.payload;
    },

    setClickUp(state, action: PayloadAction<boolean>) {
      state.document.clickUp = action.payload;

      if (action.payload) {
        state.document.numUps += 1;
      } else {
        state.document.numUps -= 1;
      }
    },

    setClickDown(state, action: PayloadAction<boolean>) {
      state.document.clickDown = action.payload;

      if (action.payload) {
        state.document.numDowns += 1;
      } else {
        state.document.numDowns -= 1;
      }
    },

    setClickBookmark(state, action: PayloadAction<boolean>) {
      state.document.clickBookmark = action.payload;
    },

    appendBestComment(state, action: PayloadAction<CommentState>) {
      const isEqualId = (comment: CommentState) =>
        comment.commentId === action.payload.commentId;

      if (state.bestComments.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.bestComments = [...state.bestComments, action.payload].sort(
          (a, b) => {
            if (a.numUps < b.numUps) {
              return 1;
            } else if (a.numUps === b.numUps) {
              return a.timestamp < b.timestamp ? 1 : -1;
            } else {
              return -1;
            }
          },
        );
      }
    },

    resetBestComments(state) {
      state.bestComments = [];
    },

    appendComment(state, action: PayloadAction<CommentState>) {
      const isEqualId = (comment: CommentState) =>
        comment.commentId === action.payload.commentId;

      if (state.comments.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.comments = [...state.comments, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    removeComment(state, action: PayloadAction<CommentState>) {
      const isEqualId = (comment: CommentState) =>
        comment.commentId === action.payload.commentId;

      const bestCommentsIndex = state.bestComments.findIndex(isEqualId);
      const commentsIndex = state.comments.findIndex(isEqualId);

      if (bestCommentsIndex >= 0) {
        state.bestComments = [
          ...state.bestComments.slice(0, bestCommentsIndex),
          ...state.bestComments.slice(
            bestCommentsIndex + 1,
            state.bestComments.length,
          ),
        ];
      }

      if (commentsIndex >= 0) {
        state.comments = [
          ...state.comments.slice(0, commentsIndex),
          ...state.comments.slice(commentsIndex + 1, state.comments.length),
        ];
        state.document.numComments -= 1;
      }
    },

    resetComments(state) {
      state.comments = [];
    },

    resetCommentsFetching(state) {
      state.commentsFetching = 0;
    },

    incrementCommentsFetching(state, action: PayloadAction<number>) {
      state.commentsFetching += action.payload;
    },

    resetCommentsFetched(state) {
      state.commentsFetched = 0;
    },

    incrementCommentsFetched(state, action: PayloadAction<number>) {
      state.commentsFetched += action.payload;
    },

    resetCommentsBlocked(state) {
      state.commentsBlocked = [];
    },

    appendCommentsBlocked(state, action: PayloadAction<string>) {
      state.commentsBlocked = [...state.commentsBlocked, action.payload];
    },

    resetDocuments(state) {
      state.documents = [];
    },

    appendDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      if (state.documents.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.documents = [...state.documents, action.payload];
      }
    },

    removeDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      const index = state.documents.findIndex(isEqualId);

      if (index >= 0) {
        state.documents = [
          ...state.documents.slice(0, index),
          ...state.documents.slice(index + 1, state.documents.length),
        ];
      }
    },

    setCommentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (comment: CommentState) =>
        comment.commentId === action.payload;

      const bestCommentsIndex = state.bestComments.findIndex(isEqualId);
      const commentsIndex = state.comments.findIndex(isEqualId);

      if (bestCommentsIndex >= 0) {
        state.bestComments[bestCommentsIndex].clickUp = true;
        state.bestComments[bestCommentsIndex].numUps += 1;
      }

      if (commentsIndex >= 0) {
        state.comments[commentsIndex].clickUp = true;
        state.comments[commentsIndex].numUps += 1;
      }
    },

    unsetCommentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (comment: CommentState) =>
        comment.commentId === action.payload;

      const bestCommentsIndex = state.bestComments.findIndex(isEqualId);
      const commentsIndex = state.comments.findIndex(isEqualId);

      if (bestCommentsIndex >= 0) {
        state.bestComments[bestCommentsIndex].clickUp = false;
        state.bestComments[bestCommentsIndex].numUps -= 1;
      }

      if (commentsIndex >= 0) {
        state.comments[commentsIndex].clickUp = false;
        state.comments[commentsIndex].numUps -= 1;
      }
    },

    setCommentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (comment: CommentState) =>
        comment.commentId === action.payload;

      const bestCommentsIndex = state.bestComments.findIndex(isEqualId);
      const commentsIndex = state.comments.findIndex(isEqualId);

      if (bestCommentsIndex >= 0) {
        state.bestComments[bestCommentsIndex].clickDown = true;
        state.bestComments[bestCommentsIndex].numDowns += 1;
      }

      if (commentsIndex >= 0) {
        state.comments[commentsIndex].clickDown = true;
        state.comments[commentsIndex].numDowns += 1;
      }
    },

    unsetCommentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (comment: CommentState) =>
        comment.commentId === action.payload;

      const bestCommentsIndex = state.bestComments.findIndex(isEqualId);
      const commentsIndex = state.comments.findIndex(isEqualId);

      if (bestCommentsIndex >= 0) {
        state.bestComments[bestCommentsIndex].clickDown = false;
        state.bestComments[bestCommentsIndex].numDowns -= 1;
      }

      if (commentsIndex >= 0) {
        state.comments[commentsIndex].clickDown = false;
        state.comments[commentsIndex].numDowns -= 1;
      }
    },

    setClickedComment(state, action: PayloadAction<string>) {
      state.clickedComment = action.payload;
    },

    unsetClickedComment(state) {
      state.clickedComment = null;
    },

    resetReplies(state) {
      state.replies = [];
    },

    incrementNumReplies(state, action: PayloadAction<number>) {
      const isCommentEqualId = (comment: CommentState) =>
        comment.commentId === state.clickedComment;

      const bestCommentsIndex = state.bestComments.findIndex(isCommentEqualId);
      const commentsIndex = state.comments.findIndex(isCommentEqualId);

      if (state.clickedComment !== null && bestCommentsIndex >= 0) {
        state.bestComments[bestCommentsIndex].numReplies += action.payload;
      }

      if (state.clickedComment !== null && commentsIndex >= 0) {
        state.comments[commentsIndex].numReplies += action.payload;
      }
    },

    appendReply(state, action: PayloadAction<ReplyState>) {
      const isReplyEqualId = (reply: ReplyState) =>
        reply.replyId === action.payload.replyId;

      const repliesIndex = state.replies.findIndex(isReplyEqualId);

      if (repliesIndex >= 0) {
        return;
      } else {
        state.replies = [...state.replies, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    removeReply(state, action: PayloadAction<ReplyState>) {
      const isEqualId = (reply: ReplyState) =>
        reply.replyId === action.payload.replyId;

      const index = state.replies.findIndex(isEqualId);

      if (index >= 0) {
        state.replies = [
          ...state.replies.slice(0, index),
          ...state.replies.slice(index + 1, state.replies.length),
        ];
      }
    },

    resetRepliesFetching(state) {
      state.repliesFetching = 0;
    },

    incrementRepliesFetching(state, action: PayloadAction<number>) {
      state.repliesFetching += action.payload;
    },

    resetRepliesFetched(state) {
      state.repliesFetched = 0;
    },

    incrementRepliesFetched(state, action: PayloadAction<number>) {
      state.repliesFetched += action.payload;
    },

    resetRepliesBlocked(state) {
      state.repliesBlocked = [];
    },

    appendRepliesBlocked(state, action: PayloadAction<string>) {
      state.repliesBlocked = [...state.repliesBlocked, action.payload];
    },

    setReplyClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

      const index = state.replies.findIndex(isEqualId);
      if (index >= 0) {
        state.replies[index].clickUp = true;
        state.replies[index].numUps += 1;
      }
    },

    unsetReplyClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

      const index = state.replies.findIndex(isEqualId);
      if (index >= 0) {
        state.replies[index].clickUp = false;
        state.replies[index].numUps -= 1;
      }
    },

    setReplyClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

      const index = state.replies.findIndex(isEqualId);
      if (index >= 0) {
        state.replies[index].clickDown = true;
        state.replies[index].numDowns += 1;
      }
    },

    unsetReplyClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (reply: ReplyState) => reply.replyId === action.payload;

      const index = state.replies.findIndex(isEqualId);
      if (index >= 0) {
        state.replies[index].clickDown = false;
        state.replies[index].numDowns -= 1;
      }
    },

    setDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickUp = true;
        state.documents[index].numUps += 1;
      }
    },

    unsetDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickUp = false;
        state.documents[index].numUps -= 1;
      }
    },

    setDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickDown = true;
        state.documents[index].numDowns += 1;
      }
    },

    unsetDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickDown = false;
        state.documents[index].numDowns -= 1;
      }
    },

    setDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickBookmark = true;
      }
    },

    unsetDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickBookmark = false;
      }
    },

    resetDocumentsMaxFetching(state) {
      state.documentsMaxFetching = 0;
    },

    incrementDocumentsMaxFetching(state, action: PayloadAction<number>) {
      state.documentsMaxFetching += action.payload;
    },

    decrementDocumentsMaxFetching(state, action: PayloadAction<number>) {
      state.documentsMaxFetching -= action.payload;
    },

    resetDocumentsFetching(state) {
      state.documentsFetching = 0;
    },

    incrementDocumentsFetching(state, action: PayloadAction<number>) {
      state.documentsFetching += action.payload;
    },

    resetDocumentsFetched(state) {
      state.documentsFetched = 0;
    },

    incrementDocumentsFetched(state, action: PayloadAction<number>) {
      state.documentsFetched += action.payload;
    },

    resetDocumentsBlocked(state) {
      state.documentsBlocked = [];
    },

    appendDocumentsBlocked(state, action: PayloadAction<string>) {
      state.documentsBlocked = [...state.documentsBlocked, action.payload];
    },

    clickNextDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      const index = state.documents.findIndex(isEqualId);

      if (index >= 0) {
        state.documents = state.documents.slice(index + 1, state.documents.length)
      }
    },
  },
});

export const readActions = read.actions;
export default read.reducer;
