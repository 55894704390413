import { useAppDispatch, useAppSelector } from "../../module/hook";
import { Loading } from "../loading/loading";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { deleteUser, getAuth, onAuthStateChanged } from "firebase/auth";
import { CameraPlus } from "@phosphor-icons/react";
import { profileActions } from "../../module/profile";
import { coreActions } from "../../module/core";
import defaultAvatar from "../../asset/image/default_avatar.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import underscore from "../../asset/image/underscore.png";
import { DocumentState } from "../../module/document";
import { UserState } from "../../module/user";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import parse from "html-react-parser";
import moment from "moment";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import hashtag from "../../asset/image/hashtag.png";
import back from "../../asset/image/back.png";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";
import LinearProgress from "@mui/material/LinearProgress";
import block from "../../asset/image/block.png";
import notify from "../../asset/image/notify.png";
import trash from "../../asset/image/trash.png";
import gRanking from "../../asset/image/g_ranking.png";
import gToken from "../../asset/image/g_token.png";
import verticalLine from "../../asset/image/vertical_line.png";
import { RightSidebar } from "../right_sidebar";

export function Profile() {
  const navigate = useNavigate();

  const isLoading = useAppSelector((state) => state.core.isLoading);

  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.currentUser === null) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          dispatch(coreActions.setIsLoading(true));

          dispatch(coreActions.setFocus("profile"));

          dispatch(profileActions.setIsEditing(false));

          const userDocRef = doc(firestore, "user", user.uid);

          getDoc(userDocRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              if (docSnap.data().hasOwnProperty("avatarUrl")) {
                dispatch(
                  profileActions.setAvatarUrl(docSnap.data()["avatarUrl"])
                );
                dispatch(
                  profileActions.setTmpAvatarUrl(docSnap.data()["avatarUrl"])
                );
              } else {
                dispatch(profileActions.setAvatarUrl(""));
                dispatch(profileActions.setTmpAvatarUrl(""));
              }

              if (docSnap.data().hasOwnProperty("name")) {
                dispatch(profileActions.setName(docSnap.data()["name"]));
              } else {
                dispatch(profileActions.setName(""));
              }

              if (docSnap.data().hasOwnProperty("nickname")) {
                dispatch(
                  profileActions.setNickname(docSnap.data()["nickname"])
                );
                dispatch(
                  profileActions.setTmpNickname(docSnap.data()["nickname"])
                );
              } else {
                dispatch(profileActions.setNickname(""));
                dispatch(profileActions.setTmpNickname(""));
              }

              if (docSnap.data().hasOwnProperty("bio")) {
                dispatch(profileActions.setBio(docSnap.data()["bio"]));
                dispatch(profileActions.setTmpBio(docSnap.data()["bio"]));
              } else {
                dispatch(profileActions.setBio(""));
                dispatch(profileActions.setTmpBio(""));
              }
            }

            const rankingQuery = query(
              collection(firestore, "ranking"),
              orderBy("timestamp", "desc"),
              limit(1)
            );

            onSnapshot(rankingQuery, async (snapshot) => {
              const newDocuments = snapshot.docs.map((elem) => elem.data());

              for (const elem of newDocuments) {
                const docRankingDocRef = doc(
                  firestore,
                  "ranking",
                  elem.timestamp.toString(),
                  "docPoint",
                  user.uid
                );
                const commentRankingDocRef = doc(
                  firestore,
                  "ranking",
                  elem.timestamp.toString(),
                  "commentPoint",
                  user.uid
                );
                const totalRankingDocRef = doc(
                  firestore,
                  "ranking",
                  elem.timestamp.toString(),
                  "totalPoint",
                  user.uid
                );

                getDoc(docRankingDocRef).then(async (docSnap) => {
                  if (docSnap.exists()) {
                    if (docSnap.data().hasOwnProperty("docPoint")) {
                      dispatch(
                        profileActions.setDocPoint(docSnap.data()["docPoint"])
                      );
                    } else {
                      dispatch(profileActions.setDocPoint(0));
                    }

                    if (docSnap.data().hasOwnProperty("docRanking")) {
                      dispatch(
                        profileActions.setDocRanking(
                          docSnap.data()["docRanking"]
                        )
                      );
                    } else {
                      dispatch(profileActions.setDocRanking(0));
                    }
                  } else {
                    dispatch(profileActions.setDocPoint(0));
                    dispatch(profileActions.setDocRanking(0));
                  }
                });

                getDoc(commentRankingDocRef).then(async (docSnap) => {
                  if (docSnap.exists()) {
                    if (docSnap.data().hasOwnProperty("commentPoint")) {
                      dispatch(
                        profileActions.setCommentPoint(
                          docSnap.data()["commentPoint"]
                        )
                      );
                    } else {
                      dispatch(profileActions.setCommentPoint(0));
                    }

                    if (docSnap.data().hasOwnProperty("commentRanking")) {
                      dispatch(
                        profileActions.setCommentRanking(
                          docSnap.data()["commentRanking"]
                        )
                      );
                    } else {
                      dispatch(profileActions.setCommentRanking(0));
                    }
                  } else {
                    dispatch(profileActions.setCommentPoint(0));
                    dispatch(profileActions.setCommentRanking(0));
                  }
                });

                getDoc(totalRankingDocRef).then(async (docSnap) => {
                  if (docSnap.exists()) {
                    if (docSnap.data().hasOwnProperty("totalPoint")) {
                      dispatch(
                        profileActions.setTotalPoint(
                          docSnap.data()["totalPoint"]
                        )
                      );
                    } else {
                      dispatch(profileActions.setTotalPoint(0));
                    }

                    if (docSnap.data().hasOwnProperty("totalRanking")) {
                      dispatch(
                        profileActions.setTotalRanking(
                          docSnap.data()["totalRanking"]
                        )
                      );
                    } else {
                      dispatch(profileActions.setTotalRanking(0));
                    }
                  } else {
                    dispatch(profileActions.setTotalPoint(0));
                    dispatch(profileActions.setTotalRanking(0));
                  }
                });
              }
            });

            const followerCollectionRef = collection(
              firestore,
              "user",
              user.uid,
              "follower"
            );
            const followingCollectionRef = collection(
              firestore,
              "user",
              user.uid,
              "following"
            );
            const postCollectionRef = collection(
              firestore,
              "user",
              user.uid,
              "post"
            );
            const bookmarkCollectionRef = collection(
              firestore,
              "user",
              user.uid,
              "bookmark"
            );

            const followerCollectionSnapshot = await getDocs(
              followerCollectionRef
            );
            const followingCollectionSnapshot = await getDocs(
              followingCollectionRef
            );
            const postCollectionSnapshot = await getDocs(postCollectionRef);
            const bookmarkCollectionSnapshot = await getDocs(
              bookmarkCollectionRef
            );

            dispatch(
              profileActions.setNumFollowers(
                followerCollectionSnapshot.docs.length
              )
            );
            dispatch(
              profileActions.setNumFollowings(
                followingCollectionSnapshot.docs.length
              )
            );
            dispatch(
              profileActions.setNumPosts(postCollectionSnapshot.docs.length)
            );
            dispatch(
              profileActions.setNumBookmarks(
                bookmarkCollectionSnapshot.docs.length
              )
            );

            dispatch(coreActions.setIsLoading(false));
          });
        } else {
          navigate("/login");
        }
      });
    } else {
      dispatch(coreActions.setIsLoading(true));

      dispatch(coreActions.setFocus("profile"));

      dispatch(profileActions.setIsEditing(false));

      const userDocRef = doc(firestore, "user", auth.currentUser!.uid);

      getDoc(userDocRef).then(async (docSnap) => {
        if (docSnap.exists()) {
          if (docSnap.data().hasOwnProperty("avatarUrl")) {
            dispatch(profileActions.setAvatarUrl(docSnap.data()["avatarUrl"]));
            dispatch(
              profileActions.setTmpAvatarUrl(docSnap.data()["avatarUrl"])
            );
          } else {
            dispatch(profileActions.setAvatarUrl(""));
            dispatch(profileActions.setTmpAvatarUrl(""));
          }

          if (docSnap.data().hasOwnProperty("name")) {
            dispatch(profileActions.setName(docSnap.data()["name"]));
          } else {
            dispatch(profileActions.setName(""));
          }

          if (docSnap.data().hasOwnProperty("nickname")) {
            dispatch(profileActions.setNickname(docSnap.data()["nickname"]));
            dispatch(profileActions.setTmpNickname(docSnap.data()["nickname"]));
          } else {
            dispatch(profileActions.setNickname(""));
            dispatch(profileActions.setTmpNickname(""));
          }

          if (docSnap.data().hasOwnProperty("bio")) {
            dispatch(profileActions.setBio(docSnap.data()["bio"]));
            dispatch(profileActions.setTmpBio(docSnap.data()["bio"]));
          } else {
            dispatch(profileActions.setBio(""));
            dispatch(profileActions.setTmpBio(""));
          }
        }

        const rankingQuery = query(
          collection(firestore, "ranking"),
          orderBy("timestamp", "desc"),
          limit(1)
        );

        onSnapshot(rankingQuery, async (snapshot) => {
          const newDocuments = snapshot.docs.map((elem) => elem.data());

          for (const elem of newDocuments) {
            const docRankingDocRef = doc(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "docPoint",
              auth.currentUser!.uid
            );
            const commentRankingDocRef = doc(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "commentPoint",
              auth.currentUser!.uid
            );
            const totalRankingDocRef = doc(
              firestore,
              "ranking",
              elem.timestamp.toString(),
              "totalPoint",
              auth.currentUser!.uid
            );

            getDoc(docRankingDocRef).then(async (docSnap) => {
              if (docSnap.exists()) {
                if (docSnap.data().hasOwnProperty("docPoint")) {
                  dispatch(
                    profileActions.setDocPoint(docSnap.data()["docPoint"])
                  );
                } else {
                  dispatch(profileActions.setDocPoint(0));
                }

                if (docSnap.data().hasOwnProperty("docRanking")) {
                  dispatch(
                    profileActions.setDocRanking(docSnap.data()["docRanking"])
                  );
                } else {
                  dispatch(profileActions.setDocRanking(0));
                }
              } else {
                dispatch(profileActions.setDocPoint(0));
                dispatch(profileActions.setDocRanking(0));
              }
            });

            getDoc(commentRankingDocRef).then(async (docSnap) => {
              if (docSnap.exists()) {
                if (docSnap.data().hasOwnProperty("commentPoint")) {
                  dispatch(
                    profileActions.setCommentPoint(
                      docSnap.data()["commentPoint"]
                    )
                  );
                } else {
                  dispatch(profileActions.setCommentPoint(0));
                }

                if (docSnap.data().hasOwnProperty("commentRanking")) {
                  dispatch(
                    profileActions.setCommentRanking(
                      docSnap.data()["commentRanking"]
                    )
                  );
                } else {
                  dispatch(profileActions.setCommentRanking(0));
                }
              } else {
                dispatch(profileActions.setCommentPoint(0));
                dispatch(profileActions.setCommentRanking(0));
              }
            });

            getDoc(totalRankingDocRef).then(async (docSnap) => {
              if (docSnap.exists()) {
                if (docSnap.data().hasOwnProperty("totalPoint")) {
                  dispatch(
                    profileActions.setTotalPoint(docSnap.data()["totalPoint"])
                  );
                } else {
                  dispatch(profileActions.setTotalPoint(0));
                }

                if (docSnap.data().hasOwnProperty("totalRanking")) {
                  dispatch(
                    profileActions.setTotalRanking(
                      docSnap.data()["totalRanking"]
                    )
                  );
                } else {
                  dispatch(profileActions.setTotalRanking(0));
                }
              } else {
                dispatch(profileActions.setTotalPoint(0));
                dispatch(profileActions.setTotalRanking(0));
              }
            });
          }
        });

        const followerCollectionRef = collection(
          firestore,
          "user",
          auth.currentUser!.uid,
          "follower"
        );
        const followingCollectionRef = collection(
          firestore,
          "user",
          auth.currentUser!.uid,
          "following"
        );
        const postCollectionRef = collection(
          firestore,
          "user",
          auth.currentUser!.uid,
          "post"
        );
        const bookmarkCollectionRef = collection(
          firestore,
          "user",
          auth.currentUser!.uid,
          "bookmark"
        );

        const followerCollectionSnapshot = await getDocs(followerCollectionRef);
        const followingCollectionSnapshot = await getDocs(
          followingCollectionRef
        );
        const postCollectionSnapshot = await getDocs(postCollectionRef);
        const bookmarkCollectionSnapshot = await getDocs(bookmarkCollectionRef);

        dispatch(
          profileActions.setNumFollowers(followerCollectionSnapshot.docs.length)
        );
        dispatch(
          profileActions.setNumFollowings(
            followingCollectionSnapshot.docs.length
          )
        );
        dispatch(
          profileActions.setNumPosts(postCollectionSnapshot.docs.length)
        );
        dispatch(
          profileActions.setNumBookmarks(bookmarkCollectionSnapshot.docs.length)
        );

        dispatch(coreActions.setIsLoading(false));
      });
    }
  }, []);

  return <div className="Profile">{isLoading ? <Loading /> : <Body />}</div>;
}

function Body() {
  /*
  return (
    <div className="Profile-Body">
      <Header />
      <Sidebar />
      <Title />
      <Main />
      <DeleteAccount />
      <RightSidebar />
    </div>
  );
  */

  return (
    <div className="Profile-Body">
      <Header />
      <Sidebar />
      <Title />
      <Main />
      <RightSidebar />
    </div>
  );
}

function Title() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <div className="Profile-Title">
      <img src={back} onClick={onClick} />
      <h1>프로필</h1>
    </div>
  );
}

function Main() {
  return (
    <div className="Profile-Main">
      <ProfileMain />
      <Details />
    </div>
  );
}

function ProfileMain() {
  return (
    <div className="Profile-ProfileMain">
      <ProfileHeader />
      <RankingAndToken />
      <Bio />
      <Edit />
      <Tab />
    </div>
  );
}

function ProfileHeader() {
  return (
    <div className="Profile-ProfileHeader">
      <Avatar />
      <Names />
    </div>
  );
}

function Avatar() {
  const isEditing = useAppSelector((state) => state.profile.isEditing);
  const avatarUrl = useAppSelector((state) => state.profile.avatarUrl);
  const tmpAvatarUrl = useAppSelector((state) => state.profile.tmpAvatarUrl);

  const dispatch = useAppDispatch();

  const avatarInputRef = useRef<HTMLInputElement>(null);

  const auth = getAuth();
  const storage = getStorage();

  const onClick = () => {
    avatarInputRef.current!.click();
  };

  const onChange = async (e: any) => {
    const avatarUpload = e.target.files[0];

    if (avatarUpload !== null) {
      dispatch(coreActions.setIsLoading(true));

      const uid = auth.currentUser!.uid;

      const avatarRef = ref(storage, "profile/" + uid);

      try {
        await uploadBytes(avatarRef, avatarUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            dispatch(profileActions.setTmpAvatarUrl(downloadURL));
          });
        });

        dispatch(coreActions.setIsLoading(false));
      } catch (error) {
        dispatch(coreActions.setIsLoading(false));

        alert("서버와의 통신에 실패하였습니다");
      }
    } else {
      dispatch(coreActions.setIsLoading(false));

      alert("이미지 파일을 선택해주세요");
    }
  };

  if (isEditing) {
    if (tmpAvatarUrl !== "") {
      return (
        <div className="Profile-Avatar">
          <img src={tmpAvatarUrl} onClick={onClick} />
          <CameraPlus className={"Profile-Avatar-CameraPlus"} />
          <input
            ref={avatarInputRef}
            onChange={onChange}
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: "none" }}
          />
        </div>
      );
    } else {
      return (
        <div className="Profile-Avatar">
          <img src={defaultAvatar} onClick={onClick} />
          <CameraPlus className={"Profile-Avatar-CameraPlus"} />
          <input
            ref={avatarInputRef}
            onChange={onChange}
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: "none" }}
          />
        </div>
      );
    }
  } else {
    if (avatarUrl !== "") {
      return (
        <div className="Profile-Avatar">
          <img src={avatarUrl} />
        </div>
      );
    } else {
      return (
        <div className="Profile-Avatar">
          <img src={defaultAvatar} />
        </div>
      );
    }
  }
}

function Names() {
  return (
    <div className="Profile-Names">
      <Nickname />
      <Name />
      <Status />
    </div>
  );
}

function Nickname() {
  const isEditing = useAppSelector((state) => state.profile.isEditing);
  const nickname = useAppSelector((state) => state.profile.nickname);
  const tmpNickname = useAppSelector((state) => state.profile.tmpNickname);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    if (e.target.value.length > 10) {
      alert("닉네임이 너무 깁니다.");
    } else {
      dispatch(profileActions.setTmpNickname(e.target.value));
    }
  };

  if (isEditing) {
    if (tmpNickname !== "") {
      return (
        <div className="Profile-Nickname">
          <input
            type="text"
            placeholder={nickname}
            required
            value={tmpNickname}
            onChange={onChange}
          />
        </div>
      );
    } else {
      return (
        <div className="Profile-Nickname">
          <input
            type="text"
            placeholder={"닉네임 없음"}
            required
            value={tmpNickname}
            onChange={onChange}
          />
        </div>
      );
    }
  } else {
    if (nickname !== "") {
      return (
        <div className="Profile-Nickname">
          <p>{nickname}</p>
        </div>
      );
    } else {
      return (
        <div className="Profile-Nickname">
          <p>닉네임 없음</p>
        </div>
      );
    }
  }
}

function Name() {
  const isEditing = useAppSelector((state) => state.profile.isEditing);
  const name = useAppSelector((state) => state.profile.name);
  const tmpName = useAppSelector((state) => state.profile.tmpName);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (value > 13) {
      alert("아이디가 너무 깁니다.");
    } else {
      dispatch(profileActions.setTmpName(value));
    }
  };

  if (name === "" && isEditing) {
    if (tmpName !== "") {
      return (
        <div className="Profile-Name">
          <input
            type="text"
            placeholder={"@" + name}
            required
            value={"@" + tmpName}
            onChange={onChange}
          />
        </div>
      );
    } else {
      return (
        <div className="Profile-Name">
          <input
            type="text"
            placeholder={"아이디 없음"}
            required
            value={"@" + tmpName}
            onChange={onChange}
          />
        </div>
      );
    }
  } else {
    if (name !== "") {
      return (
        <div className="Profile-Name">
          <p>{"@" + name}</p>
        </div>
      );
    } else {
      return (
        <div className="Profile-Name">
          <p>아이디 없음</p>
        </div>
      );
    }
  }
}

function Status() {
  return (
    <div className="Profile-Status">
      <NumFollowers />
      <NumFollowings />
      <NumPosts />
      <NumBookmarks />
    </div>
  );
}

function NumFollowers() {
  const numFollowers = useAppSelector((state) => state.profile.numFollowers);

  return (
    <div className="Profile-NumFollowers">
      <h1>팔로워</h1>
      <p>{numFollowers}</p>
    </div>
  );
}

function NumFollowings() {
  const numFollowings = useAppSelector((state) => state.profile.numFollowings);

  return (
    <div className="Profile-NumFollowings">
      <h1>팔로잉</h1>
      <p>{numFollowings}</p>
    </div>
  );
}

function NumPosts() {
  const numPosts = useAppSelector((state) => state.profile.numPosts);

  return (
    <div className="Profile-NumPosts">
      <h1>게시글</h1>
      <p>{numPosts}</p>
    </div>
  );
}

function NumBookmarks() {
  const numBookmarks = useAppSelector((state) => state.profile.numBookmarks);

  return (
    <div className="Profile-NumBookmarks">
      <h1>북마크</h1>
      <p>{numBookmarks}</p>
    </div>
  );
}

function Bio() {
  const isEditing = useAppSelector((state) => state.profile.isEditing);
  const bio = useAppSelector((state) => state.profile.bio);
  const tmpBio = useAppSelector((state) => state.profile.tmpBio);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    if (e.target.value.length > 500) {
      alert("자기소개가 너무 깁니다.");
    } else {
      dispatch(profileActions.setTmpBio(e.target.value));
    }
  };

  if (isEditing) {
    if (tmpBio !== "") {
      return (
        <div className="Profile-Bio">
          <textarea
            placeholder={tmpBio}
            required
            value={tmpBio}
            onChange={onChange}
          />
        </div>
      );
    } else {
      return (
        <div className="Profile-Bio">
          <textarea
            placeholder={"자기소개 없음"}
            required
            value={tmpBio}
            onChange={onChange}
          />
        </div>
      );
    }
  } else {
    if (bio !== "") {
      return (
        <div className="Profile-Bio">
          <p>{bio}</p>
        </div>
      );
    } else {
      return (
        <div className="Profile-Bio">
          <p>자기소개 없음</p>
        </div>
      );
    }
  }
}

function RankingAndToken() {
  return (
    <div className="Profile-RankingAndToken">
      <Ranking />
      <VerticalLine />
      <Token />
    </div>
  );
}

function VerticalLine() {
  return (
    <div className="Profile-VerticalLine">
      <img src={verticalLine} />
    </div>
  );
}

function Ranking() {
  return (
    <div className="Profile-Ranking">
      <GRanking />
      <TotalRanking />
      <DocRanking />
      <CommentRanking />
    </div>
  );
}

function GRanking() {
  return (
    <div className="Profile-GRanking">
      <img src={gRanking} />
    </div>
  );
}

function DocRanking() {
  const docPoint = useAppSelector((state) => state.profile.docPoint);
  const docRanking = useAppSelector((state) => state.profile.docRanking);

  if (docRanking !== 0) {
    return (
      <div className="Profile-DocRanking">
        <h1>글</h1>
        <p>
          {docRanking}위 ({docPoint} P)
        </p>
      </div>
    );
  } else {
    return (
      <div className="Profile-DocRanking">
        <h1>글</h1>
        <p>-</p>
      </div>
    );
  }
}

function CommentRanking() {
  const commentPoint = useAppSelector((state) => state.profile.commentPoint);
  const commentRanking = useAppSelector(
    (state) => state.profile.commentRanking
  );

  if (commentRanking !== 0) {
    return (
      <div className="Profile-CommentRanking">
        <h1>댓글</h1>
        <p>
          {commentRanking}위 ({commentPoint} P)
        </p>
      </div>
    );
  } else {
    return (
      <div className="Profile-CommentRanking">
        <h1>댓글</h1>
        <p>-</p>
      </div>
    );
  }
}

function TotalRanking() {
  const totalPoint = useAppSelector((state) => state.profile.totalPoint);
  const totalRanking = useAppSelector((state) => state.profile.totalRanking);

  if (totalRanking !== 0) {
    return (
      <div className="Profile-TotalRanking">
        <h1>전체</h1>
        <p>
          {totalRanking}위 ({totalPoint} P)
        </p>
      </div>
    );
  } else {
    return (
      <div className="Profile-TotalRanking">
        <h1>전체</h1>
        <p>-</p>
      </div>
    );
  }
}

function Token() {
  return (
    <div className="Profile-Token">
      <GToken />
      <BigComingSoon />
    </div>
  );
}

function GToken() {
  return (
    <div className="Profile-GToken">
      <img src={gToken} />
    </div>
  );
}

function BigComingSoon() {
  return (
    <div className="Profile-BigComingSoon">
      <img src={comingSoon} />
    </div>
  );
}

function Edit() {
  const isEditing = useAppSelector((state) => state.profile.isEditing);
  const tmpAvatarUrl = useAppSelector((state) => state.profile.tmpAvatarUrl);
  const nickname = useAppSelector((state) => state.profile.nickname);
  const tmpNickname = useAppSelector((state) => state.profile.tmpNickname);
  const tmpName = useAppSelector((state) => state.profile.tmpName);
  const tmpBio = useAppSelector((state) => state.profile.tmpBio);

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (isEditing) {
      dispatch(coreActions.setIsLoading(true));

      const uid = auth.currentUser!.uid;

      try {
        if (tmpName !== "") {
          const qName = query(
            collection(firestore, "user"),
            where("name", "==", tmpName)
          );
          await getDocs(qName).then((snapshot) => {
            if (!snapshot.empty) {
              alert("해당 아이디는 이미 사용 중입니다.");

              dispatch(coreActions.setIsLoading(false));

              return;
            }
          });
        }

        const qNickname = query(
          collection(firestore, "user"),
          where("nickname", "==", tmpNickname)
        );

        await getDocs(qNickname).then(async (snapshot) => {
          if (nickname !== tmpNickname && !snapshot.empty) {
            alert("해당 닉네임은 이미 사용 중입니다.");

            dispatch(coreActions.setIsLoading(false));
            dispatch(profileActions.setIsEditing(false));
          } else {
            const userDocRef = doc(firestore, "user", uid);

            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              if (tmpName !== "") {
                await updateDoc(userDocRef, {
                  avatarUrl: tmpAvatarUrl,
                  name: tmpName,
                  nickname: tmpNickname,
                  bio: tmpBio,
                });

                dispatch(profileActions.setAvatarUrl(tmpAvatarUrl));
                dispatch(profileActions.setName(tmpName));
                dispatch(profileActions.setNickname(tmpNickname));
                dispatch(profileActions.setBio(tmpBio));
              } else {
                await updateDoc(userDocRef, {
                  avatarUrl: tmpAvatarUrl,
                  nickname: tmpNickname,
                  bio: tmpBio,
                });

                dispatch(profileActions.setAvatarUrl(tmpAvatarUrl));
                dispatch(profileActions.setNickname(tmpNickname));
                dispatch(profileActions.setBio(tmpBio));
              }
            } else {
              if (tmpName !== "") {
                await setDoc(userDocRef, {
                  avatarUrl: tmpAvatarUrl,
                  name: tmpName,
                  nickname: tmpNickname,
                  bio: tmpBio,
                });

                dispatch(profileActions.setAvatarUrl(tmpAvatarUrl));
                dispatch(profileActions.setName(tmpName));
                dispatch(profileActions.setNickname(tmpNickname));
                dispatch(profileActions.setBio(tmpBio));
              } else {
                await setDoc(userDocRef, {
                  avatarUrl: tmpAvatarUrl,
                  nickname: tmpNickname,
                  bio: tmpBio,
                });

                dispatch(profileActions.setAvatarUrl(tmpAvatarUrl));
                dispatch(profileActions.setNickname(tmpNickname));
                dispatch(profileActions.setBio(tmpBio));
              }
            }

            dispatch(coreActions.setIsLoading(false));
            dispatch(profileActions.setIsEditing(false));
          }
        });
      } catch (error) {
        alert("서버와의 통신에 실패하였습니다");

        dispatch(coreActions.setIsLoading(false));
        dispatch(profileActions.setIsEditing(false));
      }
    } else {
      dispatch(profileActions.setIsEditing(true));
    }
  };

  if (isEditing) {
    return (
      <div className="Profile-Edit">
        <button onClick={onClick}>저장하기</button>
      </div>
    );
  } else {
    return (
      <div className="Profile-Edit">
        <button onClick={onClick}>프로필 수정</button>
      </div>
    );
  }
}

function Tab() {
  return (
    <div className="Profile-Tab">
      <TabFollowers />
      <TabFollowings />
      <TabPosts />
      <TabBookmarks />
    </div>
  );
}

function TabFollowers() {
  const tab = useAppSelector((state) => state.profile.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(profileActions.setTab("followers"));
  };

  if (tab === "followers") {
    return (
      <div className="Profile-TabFollowers-Focus">
        <button onClick={onClick}>팔로워</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Profile-TabFollowers">
        <button onClick={onClick}>팔로워</button>
      </div>
    );
  }
}

function TabFollowings() {
  const tab = useAppSelector((state) => state.profile.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(profileActions.setTab("followings"));
  };

  if (tab === "followings") {
    return (
      <div className="Profile-TabFollowings-Focus">
        <button onClick={onClick}>팔로잉</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Profile-TabFollowings">
        <button onClick={onClick}>팔로잉</button>
      </div>
    );
  }
}

function TabPosts() {
  const tab = useAppSelector((state) => state.profile.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(profileActions.setTab("posts"));
  };

  if (tab === "posts") {
    return (
      <div className="Profile-TabPosts-Focus">
        <button onClick={onClick}>게시글</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Profile-TabPosts">
        <button onClick={onClick}>게시글</button>
      </div>
    );
  }
}

function TabBookmarks() {
  const tab = useAppSelector((state) => state.profile.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(profileActions.setTab("bookmarks"));
  };

  if (tab === "bookmarks") {
    return (
      <div className="Profile-TabBookmarks-Focus">
        <button onClick={onClick}>북마크</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Profile-TabBookmarks">
        <button onClick={onClick}>북마크</button>
      </div>
    );
  }
}

function Details() {
  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [followerLastVisible, setFollowerLastVisible] =
    useState<any>(undefined);
  const [followingLastVisible, setFollowingLastVisible] =
    useState<any>(undefined);
  const [postLastVisible, setPostLastVisible] = useState<any>(undefined);
  const [bookmarkLastVisible, setBookmarkLastVisible] =
    useState<any>(undefined);

  const tab = useAppSelector((state) => state.profile.tab);

  const followers = useAppSelector((state) => state.profile.followers);
  const followings = useAppSelector((state) => state.profile.followings);
  const posts = useAppSelector((state) => state.profile.posts);
  const bookmarks = useAppSelector((state) => state.profile.bookmarks);

  const followerFetching = useAppSelector(
    (state) => state.profile.followerFetching
  );
  const followerFetched = useAppSelector(
    (state) => state.profile.followerFetched
  );

  const followingFetching = useAppSelector(
    (state) => state.profile.followingFetching
  );
  const followingFetched = useAppSelector(
    (state) => state.profile.followingFetched
  );

  const postFetching = useAppSelector((state) => state.profile.postFetching);
  const postFetched = useAppSelector((state) => state.profile.postFetched);

  const bookmarkFetching = useAppSelector(
    (state) => state.profile.bookmarkFetching
  );
  const bookmarkFetched = useAppSelector(
    (state) => state.profile.bookmarkFetched
  );

  const [images, setImages] = useState(new Map());

  const getFollowers = () => {
    if (auth.currentUser !== null) {
      let q;
      if (followerLastVisible === -1) {
        return;
      } else if (followerLastVisible !== undefined) {
        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "follower"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(followerLastVisible)
        );
      } else {
        dispatch(profileActions.resetFollowerBlocked());
        dispatch(profileActions.resetFollowers());

        dispatch(profileActions.resetFollowerFetching());
        dispatch(profileActions.resetFollowerFetched());

        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "follower"),
          orderBy("timestamp", "desc"),
          limit(10)
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newFollowers = snapshot.docs.map((elem) => elem.data());

        dispatch(profileActions.incrementFollowerFetching(newFollowers.length));

        for (const elem of newFollowers) {
          getFollower(elem);
        }

        if (snapshot.docs.length === 0) {
          setFollowerLastVisible(-1);
        } else {
          setFollowerLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  const getFollower = async (elem: any) => {
    let avatarUrl = "";
    let name = "";
    let nickname = "";
    let isFollowing = false;

    const userDocRef = doc(firestore, "user", elem.uid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      avatarUrl = data.avatarUrl || "";
      name = data.name || "";
      nickname = data.nickname || "";
    } else {
      dispatch(profileActions.appendFollowerBlocked(elem.uid));
      dispatch(profileActions.incrementFollowerFetched(1));
      return;
    }

    const followingDocRef = doc(
      firestore,
      "user",
      auth.currentUser!.uid,
      "following",
      elem.uid
    );

    await getDoc(followingDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        isFollowing = true;
      }
    });

    const follower: UserState = {
      uid: elem.uid,
      timestamp: elem.timestamp,
      avatarUrl: avatarUrl,
      name: name,
      nickname: nickname,
      isFollowing: isFollowing,
      docPoint: 0,
      docRanking: 0,
      commentPoint: 0,
      commentRanking: 0,
      totalPoint: 0,
      totalRanking: 0,
    };

    if (elem.timestamp < timestamp) {
      dispatch(profileActions.appendFollower(follower));
    }

    dispatch(profileActions.incrementFollowerFetched(1));
  };

  const getFollowings = () => {
    if (auth.currentUser !== null) {
      let q;
      if (followingLastVisible === -1) {
        return;
      } else if (followingLastVisible !== undefined) {
        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "following"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(followingLastVisible)
        );
      } else {
        dispatch(profileActions.resetFollowingBlocked());
        dispatch(profileActions.resetFollowings());

        dispatch(profileActions.resetFollowingFetching());
        dispatch(profileActions.resetFollowingFetched());

        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "following"),
          orderBy("timestamp", "desc"),
          limit(10)
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newFollowings = snapshot.docs.map((elem) => elem.data());

        dispatch(
          profileActions.incrementFollowingFetching(newFollowings.length)
        );

        for (const elem of newFollowings) {
          getFollowing(elem);
        }

        if (snapshot.docs.length === 0) {
          setFollowingLastVisible(-1);
        } else {
          setFollowingLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  const getFollowing = async (elem: any) => {
    let avatarUrl = "";
    let name = "";
    let nickname = "";
    let isFollowing = true;

    const userDocRef = doc(firestore, "user", elem.uid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      avatarUrl = data.avatarUrl || "";
      name = data.name || "";
      nickname = data.nickname || "";
    } else {
      dispatch(profileActions.appendFollowingBlocked(elem.uid));
      dispatch(profileActions.incrementFollowingFetched(1));
      return;
    }

    const following: UserState = {
      uid: elem.uid,
      timestamp: elem.timestamp,
      avatarUrl: avatarUrl,
      name: name,
      nickname: nickname,
      isFollowing: isFollowing,
      docPoint: 0,
      docRanking: 0,
      commentPoint: 0,
      commentRanking: 0,
      totalPoint: 0,
      totalRanking: 0,
    };

    if (elem.timestamp < timestamp) {
      dispatch(profileActions.appendFollowing(following));
    }

    dispatch(profileActions.incrementFollowingFetched(1));
  };

  async function reactiveLength(content: any) {
    if (Array.isArray(content) === true) {
      let length = 0;
      for (let child of content) {
        if (Array.isArray(child.props.children)) {
          length += await reactiveLength(child);
        } else {
          if (child.props.children && child.props.children.type === "img") {
            let img;
            if (images.has(child.props.children.props.src)) {
              img = images.get(child.props.children.props.src);
            } else {
              img = new Image();

              img.src = child.props.children.props.src;
              await img.decode();

              images.set(child.props.children.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          } else if (child.props.children && child.props.children.length) {
            length += child.props.children.length;
          } else {
            length += 1;
          }
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children)
    ) {
      let length = 0;
      for (let child of content.props.children) {
        if (
          child.hasOwnProperty("props") &&
          child.props.hasOwnProperty("children") &&
          Array.isArray(child.props.children)
        ) {
          length += await reactiveLength(child);
        } else {
          if (child.type === "img") {
            let img;
            if (images.has(child.props.src)) {
              img = images.get(child.props.src);
            } else {
              img = new Image();

              img.src = child.props.src;
              await img.decode();

              images.set(child.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          }

          length += 1;
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      content.props.children &&
      content.props.children.type === "img"
    ) {
      let img;
      if (images.has(content.props.children.props.src)) {
        img = images.get(content.props.children.props.src);
      } else {
        img = new Image();

        img.src = content.props.children.props.src;
        await img.decode();

        images.set(content.props.children.props.src, img);
        setImages(images);
      }

      if (img.naturalHeight) {
        return img.naturalHeight;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  async function extractContent(
    content: any,
    pos: number,
    accum: number
  ): Promise<any> {
    if (Array.isArray(content) === true) {
      for (let i = 0; i < content.length; i++) {
        const child = content[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children) === true
    ) {
      for (let i = 0; i < content.props.children.length; i++) {
        const child = content.props.children[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else {
      if (pos === accum) {
        return content;
      }
    }
  }

  const getPosts = async () => {
    if (auth.currentUser !== null) {
      let q;
      if (postLastVisible === -1) {
        return;
      } else if (postLastVisible !== undefined) {
        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "post"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(postLastVisible)
        );
      } else {
        dispatch(profileActions.resetPostBlocked());
        dispatch(profileActions.resetPosts());

        dispatch(profileActions.resetPostFetching());
        dispatch(profileActions.resetPostFetched());

        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "post"),
          orderBy("timestamp", "desc"),
          limit(10)
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newPosts = snapshot.docs.map((elem) => elem.data());

        dispatch(profileActions.incrementPostFetching(newPosts.length));

        for (const elem of newPosts) {
          getPost(elem.documentId);
        }

        if (snapshot.docs.length === 0) {
          setPostLastVisible(-1);
        } else {
          setPostLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  const getPost = async (documentId: string) => {
    const boardDocRef = doc(firestore, "board", documentId);

    getDoc(boardDocRef).then(async (boardDocSnap) => {
      if (boardDocSnap.exists()) {
        let authorAvatarUrl = "";
        let authorNickname = "";

        const boardData = boardDocSnap.data()!;

        const authorUid = boardData.authorUid;

        if (auth.currentUser !== null) {
          const blockDocRef = doc(
            firestore,
            "user",
            auth.currentUser.uid,
            "block",
            authorUid
          );
          const blockDocSnap = await getDoc(blockDocRef);
          if (blockDocSnap.exists()) {
            dispatch(profileActions.appendPostBlocked(documentId));
            dispatch(profileActions.incrementPostFetched(1));
            return;
          }
        }

        const userDocRef = doc(firestore, "user", authorUid);

        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          authorAvatarUrl = userData.avatarUrl || "";
          authorNickname = userData.nickname || "";
        } else {
          dispatch(profileActions.appendPostBlocked(documentId));
          dispatch(profileActions.incrementPostFetched(1));
          return;
        }

        const parsedContent = parse(boardData.content);

        const lengthLimit = 700;
        const length = await reactiveLength(parsedContent);

        let thumbnailContent: any[] = [];
        let thumbnailImageSizes: any[] = [];

        if (length >= 100) {
          let index = 0;
          let extractLength = 0;

          let batchedContent = [];

          while (index < 5 && extractLength < lengthLimit) {
            let ec = await extractContent(parsedContent, index, 0);

            if (
              ec &&
              ec.props !== undefined &&
              ec.props.children !== undefined
            ) {
              if (ec.props.children.type === "img") {
                let img;
                if (images.has(ec.props.children.props.src)) {
                  img = images.get(ec.props.children.props.src);
                } else {
                  img = new Image();

                  img.src = ec.props.children.props.src;
                  await img.decode();

                  images.set(ec.props.children.props.src, img);
                  setImages(images);
                }

                if (img.naturalHeight) {
                  if (extractLength + img.naturalHeight > lengthLimit) {
                    thumbnailImageSizes = [
                      ...thumbnailImageSizes,
                      {
                        src: ec.props.children.props.src,
                        width: img.naturalWidth,
                        height: lengthLimit - extractLength,
                      },
                    ];

                    extractLength += lengthLimit - extractLength;
                  } else {
                    thumbnailImageSizes = [
                      ...thumbnailImageSizes,
                      {
                        src: ec.props.children.props.src,
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                      },
                    ];

                    extractLength += img.naturalHeight;
                  }
                } else {
                  extractLength += 1;
                }
              } else if (ec.props.children.length) {
                extractLength += ec.props.children.length;
              } else {
                extractLength += 1;
              }

              batchedContent.push(ec);
            } else if (ec && ec.type === "img") {
              let img;
              if (images.has(ec.props!.src)) {
                img = images.get(ec.props.src);
              } else {
                img = new Image();

                img.src = ec.props.src;
                await img.decode();

                images.set(ec.props.src, img);
                setImages(images);
              }

              if (img.naturalHeight) {
                if (extractLength + img.naturalHeight > lengthLimit) {
                  thumbnailImageSizes = [
                    ...thumbnailImageSizes,
                    {
                      src: ec.props.src,
                      width: img.naturalWidth,
                      height: lengthLimit - extractLength,
                    },
                  ];

                  extractLength += lengthLimit - extractLength;
                } else {
                  thumbnailImageSizes = [
                    ...thumbnailImageSizes,
                    {
                      src: ec.props.src,
                      width: img.naturalWidth,
                      height: img.naturalHeight,
                    },
                  ];

                  extractLength += img.naturalHeight;
                }
              } else {
                extractLength += 1;
              }

              batchedContent.push(ec);
            } else if (ec) {
              extractLength += 1;

              batchedContent.push(ec);
            } else {
              extractLength += 1;
            }

            index += 1;
          }

          thumbnailContent = batchedContent;
        } else {
          thumbnailContent = [parsedContent];
        }

        const upRef = doc(firestore, "metadata", documentId, "up", "summary");
        const downRef = doc(
          firestore,
          "metadata",
          documentId,
          "down",
          "summary"
        );
        const commentRef = doc(
          firestore,
          "board",
          documentId,
          "comment",
          "summary"
        );

        const upSnap = await getDoc(upRef);
        const downSnap = await getDoc(downRef);
        const commentSnap = await getDoc(commentRef);

        let numUps = 0;
        let numDowns = 0;
        let numComments = 0;

        if (upSnap.exists()) {
          numUps = upSnap.data().numUps || 0;
        }

        if (downSnap.exists()) {
          numDowns = downSnap.data().numDowns || 0;
        }

        if (commentSnap.exists()) {
          numComments = commentSnap.data().numComments || 0;
        }

        let clickUp = false;
        let clickDown = false;
        let clickBookmark = false;

        if (auth.currentUser !== null) {
          const upDocRef = doc(
            firestore,
            "metadata",
            documentId,
            "up",
            auth.currentUser.uid
          );

          const downDocRef = doc(
            firestore,
            "metadata",
            documentId,
            "down",
            auth.currentUser.uid
          );

          const bookmarkDocRef = doc(
            firestore,
            "user",
            auth.currentUser.uid,
            "bookmark",
            documentId
          );

          await getDoc(upDocRef).then((docSnap) => {
            if (docSnap.exists()) {
              clickUp = true;
            }
          });

          await getDoc(downDocRef).then((docSnap) => {
            if (docSnap.exists()) {
              clickDown = true;
            }
          });

          await getDoc(bookmarkDocRef).then((docSnap) => {
            if (docSnap.exists()) {
              clickBookmark = true;
            }
          });
        }

        const document: DocumentState = {
          documentId: documentId,
          authorUid: boardData.authorUid,
          authorAvatarUrl: authorAvatarUrl,
          authorNickname: authorNickname,
          timestamp: boardData.timestamp,
          content: boardData.content,
          thumbnailContent: thumbnailContent,
          thumbnailImageSizes: thumbnailImageSizes,
          hashtags: boardData.hashtags.split(","),
          numUps: numUps,
          numDowns: numDowns,
          numComments: numComments,
          numTokens: 0,
          clickUp: clickUp,
          clickDown: clickDown,
          clickBookmark: clickBookmark,
        };

        if (boardData.timestamp < timestamp) {
          dispatch(profileActions.appendPost(document));
        }

        dispatch(profileActions.incrementPostFetched(1));
      } else {
        dispatch(profileActions.appendPostBlocked(documentId));
        dispatch(profileActions.incrementPostFetched(1));
      }
    });
  };

  const getBookmarks = async () => {
    if (auth.currentUser !== null) {
      let q;
      if (bookmarkLastVisible === -1) {
        return;
      } else if (bookmarkLastVisible !== undefined) {
        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "bookmark"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(bookmarkLastVisible)
        );
      } else {
        dispatch(profileActions.resetBookmarkBlocked());
        dispatch(profileActions.resetBookmarks());

        dispatch(profileActions.resetBookmarkFetching());
        dispatch(profileActions.resetBookmarkFetched());

        q = query(
          collection(firestore, "user", auth.currentUser!.uid, "bookmark"),
          orderBy("timestamp", "desc"),
          limit(10)
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newBookmarks = snapshot.docs.map((elem) => elem.data());

        dispatch(profileActions.incrementBookmarkFetching(newBookmarks.length));

        for (const elem of newBookmarks) {
          getBookmark(elem.documentId);
        }

        if (snapshot.docs.length === 0) {
          setBookmarkLastVisible(-1);
        } else {
          setBookmarkLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  const getBookmark = async (documentId: string) => {
    const boardDocRef = doc(firestore, "board", documentId);

    getDoc(boardDocRef).then(async (boardDocSnap) => {
      if (boardDocSnap.exists()) {
        let authorAvatarUrl = "";
        let authorNickname = "";

        const boardData = boardDocSnap.data()!;

        const authorUid = boardData.authorUid;

        if (auth.currentUser !== null) {
          const blockDocRef = doc(
            firestore,
            "user",
            auth.currentUser.uid,
            "block",
            authorUid
          );
          const blockDocSnap = await getDoc(blockDocRef);
          if (blockDocSnap.exists()) {
            const bookmarkDocRef = doc(
              firestore,
              "user",
              auth.currentUser!.uid,
              "bookmark",
              documentId
            );
            await deleteDoc(bookmarkDocRef);

            dispatch(profileActions.appendBookmarkBlocked(documentId));
            dispatch(profileActions.incrementBookmarkFetched(1));
            return;
          }
        }

        const userDocRef = doc(firestore, "user", authorUid);

        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          authorAvatarUrl = userData.avatarUrl || "";
          authorNickname = userData.nickname || "";
        } else {
          const bookmarkDocRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "bookmark",
            documentId
          );
          await deleteDoc(bookmarkDocRef);

          dispatch(profileActions.appendBookmarkBlocked(documentId));
          dispatch(profileActions.incrementBookmarkFetched(1));
          return;
        }

        const parsedContent = parse(boardData.content);

        const lengthLimit = 700;
        const length = await reactiveLength(parsedContent);

        let thumbnailContent: any[] = [];
        let thumbnailImageSizes: any[] = [];

        if (length >= 100) {
          let index = 0;
          let extractLength = 0;

          let batchedContent = [];

          while (index < 5 && extractLength < lengthLimit) {
            let ec = await extractContent(parsedContent, index, 0);

            if (
              ec &&
              ec.props !== undefined &&
              ec.props.children !== undefined
            ) {
              if (ec.props.children.type === "img") {
                let img;
                if (images.has(ec.props.children.props.src)) {
                  img = images.get(ec.props.children.props.src);
                } else {
                  img = new Image();

                  img.src = ec.props.children.props.src;
                  await img.decode();

                  images.set(ec.props.children.props.src, img);
                  setImages(images);
                }

                if (img.naturalHeight) {
                  if (extractLength + img.naturalHeight > lengthLimit) {
                    thumbnailImageSizes = [
                      ...thumbnailImageSizes,
                      {
                        src: ec.props.children.props.src,
                        width: img.naturalWidth,
                        height: lengthLimit - extractLength,
                      },
                    ];

                    extractLength += lengthLimit - extractLength;
                  } else {
                    thumbnailImageSizes = [
                      ...thumbnailImageSizes,
                      {
                        src: ec.props.children.props.src,
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                      },
                    ];

                    extractLength += img.naturalHeight;
                  }
                } else {
                  extractLength += 1;
                }
              } else if (ec.props.children.length) {
                extractLength += ec.props.children.length;
              } else {
                extractLength += 1;
              }

              batchedContent.push(ec);
            } else if (ec && ec.type === "img") {
              let img;
              if (images.has(ec.props!.src)) {
                img = images.get(ec.props.src);
              } else {
                img = new Image();

                img.src = ec.props.src;
                await img.decode();

                images.set(ec.props.src, img);
                setImages(images);
              }

              if (img.naturalHeight) {
                if (extractLength + img.naturalHeight > lengthLimit) {
                  thumbnailImageSizes = [
                    ...thumbnailImageSizes,
                    {
                      src: ec.props.src,
                      width: img.naturalWidth,
                      height: lengthLimit - extractLength,
                    },
                  ];

                  extractLength += lengthLimit - extractLength;
                } else {
                  thumbnailImageSizes = [
                    ...thumbnailImageSizes,
                    {
                      src: ec.props.src,
                      width: img.naturalWidth,
                      height: img.naturalHeight,
                    },
                  ];

                  extractLength += img.naturalHeight;
                }
              } else {
                extractLength += 1;
              }

              batchedContent.push(ec);
            } else if (ec) {
              extractLength += 1;

              batchedContent.push(ec);
            } else {
              extractLength += 1;
            }

            index += 1;
          }

          thumbnailContent = batchedContent;
        } else {
          thumbnailContent = [parsedContent];
        }

        const upRef = doc(firestore, "metadata", documentId, "up", "summary");
        const downRef = doc(
          firestore,
          "metadata",
          documentId,
          "down",
          "summary"
        );
        const commentRef = doc(
          firestore,
          "board",
          documentId,
          "comment",
          "summary"
        );

        const upSnap = await getDoc(upRef);
        const downSnap = await getDoc(downRef);
        const commentSnap = await getDoc(commentRef);

        let numUps = 0;
        let numDowns = 0;
        let numComments = 0;

        if (upSnap.exists()) {
          numUps = upSnap.data().numUps || 0;
        }

        if (downSnap.exists()) {
          numDowns = downSnap.data().numDowns || 0;
        }

        if (commentSnap.exists()) {
          numComments = commentSnap.data().numComments || 0;
        }

        let clickUp = false;
        let clickDown = false;
        let clickBookmark = false;

        if (auth.currentUser !== null) {
          const upDocRef = doc(
            firestore,
            "metadata",
            documentId,
            "up",
            auth.currentUser.uid
          );

          const downDocRef = doc(
            firestore,
            "metadata",
            documentId,
            "down",
            auth.currentUser.uid
          );

          const bookmarkDocRef = doc(
            firestore,
            "user",
            auth.currentUser.uid,
            "bookmark",
            documentId
          );

          await getDoc(upDocRef).then((docSnap) => {
            if (docSnap.exists()) {
              clickUp = true;
            }
          });

          await getDoc(downDocRef).then((docSnap) => {
            if (docSnap.exists()) {
              clickDown = true;
            }
          });

          await getDoc(bookmarkDocRef).then((docSnap) => {
            if (docSnap.exists()) {
              clickBookmark = true;
            }
          });
        }

        const document: DocumentState = {
          documentId: documentId,
          authorUid: boardData.authorUid,
          authorAvatarUrl: authorAvatarUrl,
          authorNickname: authorNickname,
          timestamp: boardData.timestamp,
          content: boardData.content,
          thumbnailContent: thumbnailContent,
          thumbnailImageSizes: thumbnailImageSizes,
          hashtags: boardData.hashtags.split(","),
          numUps: numUps,
          numDowns: numDowns,
          numComments: numComments,
          numTokens: 0,
          clickUp: clickUp,
          clickDown: clickDown,
          clickBookmark: clickBookmark,
        };

        if (boardData.timestamp < timestamp) {
          dispatch(profileActions.appendBookmark(document));
        }

        dispatch(profileActions.incrementBookmarkFetched(1));
      } else {
        const bookmarkDocRef = doc(
          firestore,
          "user",
          auth.currentUser!.uid,
          "bookmark",
          documentId
        );
        await deleteDoc(bookmarkDocRef);

        dispatch(profileActions.appendBookmarkBlocked(documentId));
        dispatch(profileActions.incrementBookmarkFetched(1));
      }
    });
  };

  const getItems = () => {
    if (tab === "followers") {
      getFollowers();
    } else if (tab === "followings") {
      getFollowings();
    } else if (tab === "posts") {
      getPosts();
    } else if (tab === "bookmarks") {
      getBookmarks();
    }
  };

  useEffect(() => {
    getItems();
  }, [tab]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tab === "following" &&
        followingFetched === followingFetching &&
        window.scrollY > document.body.scrollHeight * 0.67
      ) {
        getItems();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [followingFetched, followingFetching]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tab === "follower" &&
        followingFetched === followingFetching &&
        window.scrollY > document.body.scrollHeight * 0.67
      ) {
        getItems();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [followerFetched, followerFetching]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tab === "post" &&
        followingFetched === followingFetching &&
        window.scrollY > document.body.scrollHeight * 0.67
      ) {
        getItems();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [postFetched, postFetching]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tab === "bookmark" &&
        followingFetched === followingFetching &&
        window.scrollY > document.body.scrollHeight * 0.67
      ) {
        getItems();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [bookmarkFetched, bookmarkFetching]);

  useBottomScrollListener(getItems);

  if (tab === "followers") {
    if (followerFetched < followerFetching) {
      return (
        <div className="Profile-Details">
          <LoadingBar />
          <Followers />
        </div>
      );
    } else {
      if (followers.length > 0) {
        return (
          <div className="Profile-Details">
            <Followers />
          </div>
        );
      } else {
        return (
          <div className="Profile-Followers-Void">
            <p>팔로워가 없습니다.</p>
          </div>
        );
      }
    }
  } else if (tab === "followings") {
    if (followingFetched < followingFetching) {
      return (
        <div className="Profile-Details">
          <LoadingBar />
          <Followings />
        </div>
      );
    } else {
      if (followings.length > 0) {
        return (
          <div className="Profile-Details">
            <Followings />
          </div>
        );
      } else {
        return (
          <div className="Profile-Followings-Void">
            <p>팔로잉이 없습니다.</p>
          </div>
        );
      }
    }
  } else if (tab === "posts") {
    if (postFetched < postFetching) {
      return (
        <div className="Profile-Details">
          <LoadingBar />
          <Posts />
        </div>
      );
    } else {
      if (posts.length > 0) {
        return (
          <div className="Profile-Details">
            <Posts />
          </div>
        );
      } else {
        return (
          <div className="Profile-Posts-Void">
            <p>게시글이 없습니다.</p>
          </div>
        );
      }
    }
  } else {
    if (bookmarkFetched < bookmarkFetching) {
      return (
        <div className="Profile-Details">
          <LoadingBar />
          <Bookmarks />
        </div>
      );
    } else {
      if (bookmarks.length > 0) {
        return (
          <div className="Profile-Details">
            <Bookmarks />
          </div>
        );
      } else {
        return (
          <div className="Profile-Bookmarks-Void">
            <p>북마크가 없습니다.</p>
          </div>
        );
      }
    }
  }
}

function LoadingBar() {
  return (
    <div className="Profile-LoadingBar">
      <LinearProgress />
    </div>
  );
}

function Followers() {
  const followers = useAppSelector((state) => state.profile.followers);

  const f = followers.map((item, _) => {
    return <Follower follower={item} />;
  });

  return <div className="Profile-Followers">{f}</div>;
}

function Follower(props: { follower: UserState }) {
  return (
    <div className="Profile-Follower">
      <UserHeader user={props.follower} />
    </div>
  );
}

function Followings() {
  const followings = useAppSelector((state) => state.profile.followings);

  const f = followings.map((item, _) => {
    return <Following following={item} />;
  });

  return <div className="Profile-Followings">{f}</div>;
}

function Following(props: { following: UserState }) {
  return (
    <div className="Profile-Following">
      <UserHeader user={props.following} />
    </div>
  );
}

function UserHeader(props: { user: UserState }) {
  return (
    <div className="Profile-UserHeader">
      <UserAvatar user={props.user} />
      <UserNames user={props.user} />
      <UserFollowOrUnfollow user={props.user} />
    </div>
  );
}

function UserAvatar(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.avatarUrl !== "") {
    return (
      <div className="Profile-UserAvatar">
        <img src={props.user.avatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Profile-UserAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function UserNames(props: { user: UserState }) {
  return (
    <div className="Profile-UserNames">
      <UserName user={props.user} />
      <UserNickname user={props.user} />
    </div>
  );
}

function UserName(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.name !== "") {
    return (
      <div className="Profile-UserName">
        <button onClick={onClick}>{props.user.name}</button>
      </div>
    );
  } else {
    return (
      <div className="Profile-UserName">
        <button onClick={onClick}>이름 없음</button>
      </div>
    );
  }
}

function UserNickname(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.nickname !== "") {
    return (
      <div className="Profile-UserNickname">
        <button onClick={onClick}>{props.user.nickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Profile-UserNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function UserFollowOrUnfollow(props: { user: UserState }) {
  const numFollowings = useAppSelector((state) => state.profile.numFollowings);

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    dispatch(coreActions.setIsLoading(true));

    if (props.user.uid === auth.currentUser!.uid) {
      alert("나 자신을 팔로우 할 수 없습니다");
    } else {
      if (props.user.isFollowing) {
        const followingRef = doc(
          firestore,
          "user",
          auth.currentUser!.uid,
          "following",
          props.user.uid
        );

        const followerRef = doc(
          firestore,
          "user",
          props.user.uid,
          "follower",
          auth.currentUser!.uid
        );

        await deleteDoc(followingRef);
        await deleteDoc(followerRef);

        dispatch(profileActions.setNumFollowings(numFollowings - 1));
        dispatch(profileActions.unsetFollowersFollowing(props.user));

        const timestamp = Date.now();

        const following: UserState = {
          uid: props.user.uid,
          timestamp: timestamp,
          avatarUrl: props.user.avatarUrl,
          name: props.user.name,
          nickname: props.user.nickname,
          isFollowing: false,
          docPoint: 0,
          docRanking: 0,
          commentPoint: 0,
          commentRanking: 0,
          totalPoint: 0,
          totalRanking: 0,
        };

        dispatch(profileActions.deleteFollowing(following));
      } else {
        const followingRef = doc(
          firestore,
          "user",
          auth.currentUser!.uid,
          "following",
          props.user.uid
        );

        const followerRef = doc(
          firestore,
          "user",
          props.user.uid,
          "follower",
          auth.currentUser!.uid
        );

        const timestamp = Date.now();

        await setDoc(followingRef, {
          uid: props.user.uid,
          timestamp: timestamp,
        });

        await setDoc(followerRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        dispatch(profileActions.setNumFollowings(numFollowings + 1));
        dispatch(profileActions.setFollowersFollowing(props.user));

        const following: UserState = {
          uid: props.user.uid,
          timestamp: timestamp,
          avatarUrl: props.user.avatarUrl,
          name: props.user.name,
          nickname: props.user.nickname,
          isFollowing: true,
          docPoint: 0,
          docRanking: 0,
          commentPoint: 0,
          commentRanking: 0,
          totalPoint: 0,
          totalRanking: 0,
        };

        dispatch(profileActions.appendFollowing(following));
      }
    }

    dispatch(coreActions.setIsLoading(false));
  };

  if (props.user.isFollowing) {
    return (
      <div className="Profile-UserFollowOrUnfollow">
        <button onClick={onClick}>팔로잉 중단</button>
      </div>
    );
  } else {
    return (
      <div className="Profile-UserFollowOrUnfollow">
        <button onClick={onClick}>팔로잉</button>
      </div>
    );
  }
}

function Posts() {
  const posts = useAppSelector((state) => state.profile.posts);

  return (
    <div className="Profile-Posts">
      {posts.map((item, _) => {
        return <Document document={item} />;
      })}
    </div>
  );
}

function Bookmarks() {
  const bookmarks = useAppSelector((state) => state.profile.bookmarks);

  return (
    <div className="Profile-Bookmarks">
      {bookmarks.map((item, _) => {
        return <Document document={item} />;
      })}
    </div>
  );
}

function Document(props: { document: DocumentState }) {
  if (props.document.hashtags.length > 0 && props.document.hashtags[0] !== "") {
    return (
      <div className="Profile-Document">
        <DocumentHeader document={props.document} />
        <ThumbnailContent document={props.document} />
        <Hashtags document={props.document} />
        <ReadMore document={props.document} />
        <Summary document={props.document} />
      </div>
    );
  } else {
    return (
      <div className="Profile-Document">
        <DocumentHeader document={props.document} />
        <ThumbnailContent document={props.document} />
        <ReadMore document={props.document} />
        <Summary document={props.document} />
      </div>
    );
  }
}

function DocumentHeader(props: { document: DocumentState }) {
  return (
    <div className="Profile-DocumentHeader">
      <DocumentAvatar document={props.document} />
      <DocumentNickname document={props.document} />
      <Time document={props.document} />
      <Bookmark document={props.document} />
      <Misc document={props.document} />
    </div>
  );
}

function DocumentAvatar(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorAvatarUrl !== "") {
    return (
      <div className="Profile-DocumentAvatar">
        <img src={props.document.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Profile-DocumentAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function DocumentNickname(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorNickname !== "") {
    return (
      <div className="Profile-DocumentNickname">
        <button onClick={onClick}>{props.document.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Profile-DocumentNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function Time(props: { document: DocumentState }) {
  const endTimestamp = Date.now();

  const diff = endTimestamp - props.document.timestamp;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) {
    return (
      <div className="Profile-Time">
        <p>방금 전</p>
      </div>
    );
  } else if (hours < 1) {
    return (
      <div className="Profile-Time">
        <p>{minutes} 분 전</p>
      </div>
    );
  } else if (days < 1) {
    return (
      <div className="Profile-Time">
        <p>{hours} 시간 전</p>
      </div>
    );
  } else {
    const date = moment(props.document.timestamp);

    return (
      <div className="Profile-Time">
        <p>{date.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function Bookmark(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      const bookmarkDocRef = doc(
        firestore,
        "user",
        auth.currentUser.uid,
        "bookmark",
        props.document.documentId
      );

      if (props.document.clickBookmark) {
        dispatch(
          profileActions.unsetDocumentClickBookmark(props.document.documentId)
        );

        await deleteDoc(bookmarkDocRef);
      } else {
        dispatch(
          profileActions.setDocumentClickBookmark(props.document.documentId)
        );

        const timestamp = Date.now();

        await setDoc(bookmarkDocRef, {
          documentId: props.document.documentId,
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });
      }
    } else {
      navigate("/login");
    }
  };

  if (auth.currentUser !== null) {
    if (props.document.clickBookmark) {
      return (
        <div className="Profile-Bookmark">
          <button onClick={onClick}>
            <img src={bookmarkFocus} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="Profile-Bookmark">
          <button onClick={onClick}>
            <img src={bookmark} />
          </button>
        </div>
      );
    }
  } else {
    return (
      <div className="Profile-Bookmark">
        <button onClick={onClick}>
          <img src={bookmark} />
        </button>
      </div>
    );
  }
}

function Misc(props: { document: DocumentState }) {
  const isAdmin = useAppSelector((state) => state.core.isAdmin);

  const auth = getAuth();

  const [click, setClick] = useState(false);

  const onClick = () => {
    if (click) {
      setClick(false);
    } else {
      setClick(true);
    }
  };

  if (click) {
    if (
      auth.currentUser !== null &&
      (props.document.authorUid === auth.currentUser.uid || isAdmin)
    ) {
      return (
        <div className="Profile-Misc">
          <button onClick={onClick}>
            <img src={threeDots} />
          </button>
          <div className="Profile-MiscFocus-Mine">
            <Delete document={props.document} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="Profile-Misc">
          <button onClick={onClick}>
            <img src={threeDots} />
          </button>
          <div className="Profile-MiscFocus">
            <Block document={props.document} />
            <Report document={props.document} />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="Profile-Misc">
        <button onClick={onClick}>
          <img src={threeDots} />
        </button>
      </div>
    );
  }
}

function Block(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      if (auth.currentUser.uid !== props.document.authorNickname) {
        const answer = window.confirm(
          `${props.document.authorNickname}님의 게시글을 모두 차단하겠습니까?`
        );

        if (answer) {
          const timestamp = Date.now();

          const docRef = doc(
            firestore,
            "user",
            auth.currentUser.uid,
            "block",
            props.document.authorUid
          );

          await setDoc(docRef, {
            uid: props.document.authorUid,
            timestamp: timestamp,
          });

          navigate(0);
        }
      } else {
        alert("본인을 차단할 수는 없습니다.");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="Profile-Block">
      <button onClick={onClick}>
        <img src={block} />
        <p>차단하기</p>
      </button>
    </div>
  );
}

function Report(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const auth = getAuth();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      navigate("/report", {
        state: {
          documentId: props.document.documentId,
          commentId: "",
          replyId: "",
        },
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="Profile-Report">
      <button onClick={onClick}>
        <img src={notify} />
        <p>신고하기</p>
      </button>
    </div>
  );
}

function Delete(props: { document: DocumentState }) {
  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const onClick = async () => {
    const answer = window.confirm("정말 삭제하시겠습니까?");

    if (answer) {
      dispatch(coreActions.setIsLoading(true));

      await deleteDoc(doc(firestore, "metadata", props.document.documentId));
      await deleteDoc(
        doc(
          firestore,
          "user",
          props.document.authorUid,
          "post",
          props.document.documentId
        )
      );

      for (const hashtag of props.document.hashtags) {
        if (hashtag !== "") {
          await deleteDoc(
            doc(
              firestore,
              "hashtag",
              hashtag,
              "post",
              props.document.documentId
            )
          );
        }
      }

      await deleteDoc(doc(firestore, "board", props.document.documentId));

      dispatch(profileActions.removeDocument(props.document));

      dispatch(coreActions.setIsLoading(false));
    }
  };

  return (
    <div className="Profile-Delete">
      <button onClick={onClick}>
        <img src={trash} />
        <p>삭제하기</p>
      </button>
    </div>
  );
}

function ThumbnailContent(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const d: DocumentState = {
    documentId: props.document.documentId,
    authorUid: props.document.authorUid,
    authorAvatarUrl: props.document.authorAvatarUrl,
    authorNickname: props.document.authorNickname,
    timestamp: props.document.timestamp,
    content: props.document.content,
    thumbnailContent: [],
    thumbnailImageSizes: [],
    hashtags: props.document.hashtags,
    numUps: props.document.numUps,
    numDowns: props.document.numDowns,
    numComments: props.document.numComments,
    numTokens: props.document.numTokens,
    clickUp: props.document.clickUp,
    clickDown: props.document.clickDown,
    clickBookmark: props.document.clickBookmark,
  };

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`, {
      state: {
        document: d,
      },
    });
    navigate(0);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Profile-ThumbnailContent">
      <button onClick={onClick}>
        {props.document.thumbnailContent.map((item, _) => {
          if (item.props !== undefined && item.props.children !== undefined) {
            if (item.props.children.type === "img") {
              for (let imageSize of props.document.thumbnailImageSizes) {
                if (imageSize.src === item.props.children.props.src) {
                  if (
                    0.9 * 0.44 * windowDimensions.width - 20 <
                    imageSize.width
                  ) {
                    return (
                      <img
                        style={{
                          width: 0.85 * 0.44 * windowDimensions.width - 20,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  } else {
                    return (
                      <img
                        style={{
                          width: imageSize.width,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  }
                }
              }

              return item;
            } else {
              return item;
            }
          } else if (item.type === "img" && item.props !== undefined) {
            for (let imageSize of props.document.thumbnailImageSizes) {
              if (imageSize.src === item.props.src) {
                if (
                  0.9 * 0.44 * windowDimensions.width - 20 <
                  imageSize.width
                ) {
                  return (
                    <img
                      style={{
                        width: 0.85 * 0.44 * windowDimensions.width - 20,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                } else {
                  return (
                    <img
                      style={{
                        width: imageSize.width,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                }
              }
            }

            return item;
          } else {
            return item;
          }
        })}
      </button>
    </div>
  );
}

function Hashtags(props: { document: DocumentState }) {
  return (
    <div className="Profile-Hashtags">
      {
        <ul>
          {props.document.hashtags.map((hashtag, index) => (
            <li key={index}>
              <Hashtag hashtag={hashtag} />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Hashtag(props: { hashtag: string }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/search_query/${props.hashtag.slice(1)}`);
  };

  return (
    <div className="Profile-Hashtag">
      <img src={hashtag} />
      <button className="Profile-Hashtag-tag" onClick={onClick}>
        {props.hashtag.slice(1)}
      </button>
    </div>
  );
}

function ReadMore(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const d: DocumentState = {
    documentId: props.document.documentId,
    authorUid: props.document.authorUid,
    authorAvatarUrl: props.document.authorAvatarUrl,
    authorNickname: props.document.authorNickname,
    timestamp: props.document.timestamp,
    content: props.document.content,
    thumbnailContent: [],
    thumbnailImageSizes: [],
    hashtags: props.document.hashtags,
    numUps: props.document.numUps,
    numDowns: props.document.numDowns,
    numComments: props.document.numComments,
    numTokens: props.document.numTokens,
    clickUp: props.document.clickUp,
    clickDown: props.document.clickDown,
    clickBookmark: props.document.clickBookmark,
  };

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`, {
      state: {
        document: d,
      },
    });
    navigate(0);
  };

  return (
    <div className="Profile-ReadMore">
      <button onClick={onClick}>
        <img src={more} />
      </button>
    </div>
  );
}

function Summary(props: { document: DocumentState }) {
  return (
    <div className="Profile-Summary">
      <NumUps document={props.document} />
      <NumDowns document={props.document} />
      <NumComments document={props.document} />
      <NumTokens document={props.document} />
    </div>
  );
}

function NumUps(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      const upDocRef = doc(
        firestore,
        "metadata",
        props.document.documentId,
        "up",
        auth.currentUser.uid
      );

      if (props.document.clickUp) {
        dispatch(
          profileActions.unsetDocumentClickUp(props.document.documentId)
        );

        await deleteDoc(upDocRef);
      } else {
        dispatch(profileActions.setDocumentClickUp(props.document.documentId));

        const upTimestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });
      }
    } else {
      navigate("/login");
    }
  };

  if (auth.currentUser !== null) {
    if (props.document.clickUp) {
      return (
        <div className="Profile-NumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{props.document.numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Profile-NumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{props.document.numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    return (
      <div className="Profile-NumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{props.document.numUps}</p>
        </button>
      </div>
    );
  }
}

function NumDowns(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      const downDocRef = doc(
        firestore,
        "metadata",
        props.document.documentId,
        "down",
        auth.currentUser.uid
      );

      if (props.document.clickDown) {
        dispatch(
          profileActions.unsetDocumentClickDown(props.document.documentId)
        );

        await deleteDoc(downDocRef);
      } else {
        dispatch(
          profileActions.setDocumentClickDown(props.document.documentId)
        );

        const downTimestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: downTimestamp,
        });
      }
    } else {
      navigate("/login");
    }
  };

  if (auth.currentUser !== null) {
    if (props.document.clickDown) {
      return (
        <div className="Profile-NumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{props.document.numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Profile-NumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{props.document.numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    return (
      <div className="Profile-NumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{props.document.numDowns}</p>
        </button>
      </div>
    );
  }
}

function NumComments(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const d: DocumentState = {
    documentId: props.document.documentId,
    authorUid: props.document.authorUid,
    authorAvatarUrl: props.document.authorAvatarUrl,
    authorNickname: props.document.authorNickname,
    timestamp: props.document.timestamp,
    content: props.document.content,
    thumbnailContent: [],
    thumbnailImageSizes: [],
    hashtags: props.document.hashtags,
    numUps: props.document.numUps,
    numDowns: props.document.numDowns,
    numComments: props.document.numComments,
    numTokens: props.document.numTokens,
    clickUp: props.document.clickUp,
    clickDown: props.document.clickDown,
    clickBookmark: props.document.clickBookmark,
  };

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`, {
      state: {
        document: d,
      },
    });
    navigate(0);
  };

  return (
    <div className="Profile-NumComments">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{props.document.numComments}</p>
      </button>
    </div>
  );
}

function NumTokens(props: { document: DocumentState }) {
  return (
    <div className="Profile-NumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="Profile-ComingSoon">
      <button>
        <img src={comingSoon} />
      </button>
    </div>
  );
}

function DeleteAccount() {
  const navigate = useNavigate();

  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      const answer = window.confirm("정말 계정을 삭제하시겠습니까?");

      if (answer) {
        dispatch(coreActions.setIsLoading(true));

        const docRef = doc(firestore, "user", auth.currentUser!.uid);
        await deleteDoc(docRef);

        await deleteUser(auth.currentUser);

        dispatch(coreActions.setIsLoading(false));

        navigate("/");
      }
    }
  };

  return (
    <div className="Profile-DeleteAccount">
      <button onClick={onClick}>회원탈퇴</button>
    </div>
  );
}
