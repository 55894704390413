import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";

export interface RandomState {
  documents: DocumentState[];
  maxFetching: number;
  fetching: number;
  fetched: number;
  blocked: string[];
}

const initialState: RandomState = {
  documents: [],
  maxFetching: 0,
  fetching: 0,
  fetched: 0,
  blocked: [],
};

export const random = createSlice({
  name: "random",
  initialState,
  reducers: {
    resetDocuments(state) {
      state.documents = [];
    },

    appendDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      if (state.documents.findIndex(isEqualId) >= 0) {
        return;
      } else {
        state.documents = [...state.documents, action.payload];
      }
    },

    removeDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload.documentId;

      const index = state.documents.findIndex(isEqualId);

      if (index >= 0) {
        state.documents = [
          ...state.documents.slice(0, index),
          ...state.documents.slice(index + 1, state.documents.length),
        ];
      }
    },

    resetMaxFetching(state) {
      state.maxFetching = 0;
    },

    incrementMaxFetching(state, action: PayloadAction<number>) {
      state.maxFetching += action.payload;
    },

    decrementMaxFetching(state, action: PayloadAction<number>) {
      state.maxFetching -= action.payload;
    },

    setDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickUp = true;
        state.documents[index].numUps += 1;
      }
    },

    unsetDocumentClickUp(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickUp = false;
        state.documents[index].numUps -= 1;
      }
    },

    setDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickDown = true;
        state.documents[index].numDowns += 1;
      }
    },

    unsetDocumentClickDown(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickDown = false;
        state.documents[index].numDowns -= 1;
      }
    },

    setDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickBookmark = true;
      }
    },

    unsetDocumentClickBookmark(state, action: PayloadAction<string>) {
      const isEqualId = (document: DocumentState) =>
        document.documentId === action.payload;

      const index = state.documents.findIndex(isEqualId);
      if (index >= 0) {
        state.documents[index].clickBookmark = false;
      }
    },

    resetFetching(state) {
      state.fetching = 0;
    },

    incrementFetching(state, action: PayloadAction<number>) {
      state.fetching += action.payload;
    },

    resetFetched(state) {
      state.fetched = 0;
    },

    incrementFetched(state, action: PayloadAction<number>) {
      state.fetched += action.payload;
    },

    resetBlocked(state) {
      state.blocked = [];
    },

    appendBlocked(state, action: PayloadAction<string>) {
      state.blocked = [...state.blocked, action.payload];
    },
  },
});

export const randomActions = random.actions;
export default random.reducer;
