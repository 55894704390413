import logo from "../asset/image/logo.png";
import menu from "../asset/image/menu.png";
import underscore from "../asset/image/underscore.png";
import { useNavigate } from "react-router-dom";
import "./mobile_header.css";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { coreActions } from "../module/core";
import { useAppDispatch, useAppSelector } from "../module/hook";
import React, { useEffect, useRef, useState } from "react";
import defaultAvatar from "../asset/image/default_avatar.png";
import { doc, getDoc, getFirestore } from "firebase/firestore";

export function MobileHeader() {
  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const checkAdmin = async () => {
    if (auth.currentUser !== null) {
      const userDocRef = doc(firestore, "admin", auth.currentUser!.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (
        userDocSnap.exists() &&
        userDocSnap.data()!.uid === auth.currentUser!.uid
      ) {
        dispatch(coreActions.setIsAdmin(true));
      } else {
        dispatch(coreActions.setIsAdmin(false));
      }
    } else {
      dispatch(coreActions.setIsAdmin(false));
    }
  };

  useEffect(() => {
    checkAdmin();
  }, [auth.currentUser]);

  return (
    <div className="MobileHeader">
      <Logo />
      <Action />
    </div>
  );
}

function Logo() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("random"));

    navigate("/");
  };

  return (
    <div className="MobileHeader-Logo">
      <img src={logo} onClick={onClick} />
    </div>
  );
}

function Action() {
  return (
    <div className="MobileHeader-Action">
      <Menu />
      <LoginOrAvatar />
    </div>
  );
}

function Menu() {
  const [click, setClick] = useState(false);

  const onClick = () => {
    if (click) {
      setClick(false);
    } else {
      setClick(true);
    }
  };

  if (click) {
    return (
      <div className="MobileHeader-Menu">
        <img src={menu} onClick={onClick} />
        <MenuFocus onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="MobileHeader-Menu">
        <img src={menu} onClick={onClick} />
      </div>
    );
  }
}

function MenuFocus(props: { onClick: any }) {
  const auth = getAuth();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        props.onClick();
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (auth.currentUser !== null) {
    return (
      <div className="MobileHeader-MenuFocusLogout" ref={ref}>
        <Ranking />
        <Coin />
        <Event />
        <Notice />
        <Block />
        <Logout />
      </div>
    );
  } else {
    return (
      <div className="MobileHeader-MenuFocus" ref={ref}>
        <Ranking />
        <Coin />
        <Event />
        <Notice />
        <Block />
      </div>
    );
  }
}

function Ranking() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/ranking");
  };

  return (
    <div className="MobileHeader-Ranking">
      <button onClick={onClick}>
        <p>랭킹</p>
      </button>
    </div>
  );
}

function Coin() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/token");
  };

  return (
    <div className="MobileHeader-Coin">
      <button>
        <p>토큰</p>
      </button>
    </div>
  );
}

function Event() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/event");
  };

  return (
    <div className="MobileHeader-Event">
      <button onClick={onClick}>
        <p>이벤트</p>
      </button>
    </div>
  );
}

function Notice() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/notice");
  };

  return (
    <div className="MobileHeader-Notice">
      <button onClick={onClick}>
        <p>공지사항</p>
      </button>
    </div>
  );
}

function Block() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/block");
  };

  return (
    <div className="MobileHeader-Block">
      <button onClick={onClick}>
        <p>차단된 프로필</p>
      </button>
    </div>
  );
}

function Logout() {
  const navigate = useNavigate();

  const auth = getAuth();

  const dispatch = useAppDispatch();

  const onClick = () => {
    const answer = window.confirm("로그아웃 하시겠습니까?");

    if (answer && auth.currentUser !== null) {
      dispatch(coreActions.setIsAdmin(false));

      signOut(auth);
      navigate(0);
    }
  };

  return (
    <div className="MobileHeader-Logout">
      <button onClick={onClick}>
        <p>로그아웃</p>
      </button>
    </div>
  );
}

function LoginOrAvatar() {
  const navigate = useNavigate();

  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, "user", auth.currentUser.uid);

      getDoc(docRef).then((docSnap) => {
        const data = docSnap.data();

        if (data) {
          setAvatarUrl(data!.avatarUrl || "");
        }
      });
    } else {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const docRef = doc(firestore, "user", user.uid);

          getDoc(docRef).then((docSnap) => {
            const data = docSnap.data();

            if (data) {
              setAvatarUrl(data!.avatarUrl || "");
            }
          });
        }
      });
    }
  }, [auth.currentUser]);

  const onClick = () => {
    if (auth.currentUser !== null) {
      dispatch(coreActions.setFocus("profile"));

      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  if (auth.currentUser !== null) {
    if (avatarUrl !== "") {
      return (
        <div className="MobileHeader-Avatar">
          <img src={avatarUrl} onClick={onClick} />
        </div>
      );
    } else {
      return (
        <div className="MobileHeader-Avatar">
          <img src={defaultAvatar} onClick={onClick} />
        </div>
      );
    }
  } else {
    return (
      <div className="MobileHeader-Login">
        <button onClick={onClick}>로그인</button>
      </div>
    );
  }
}
