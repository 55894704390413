import "./easy_sign_up.css";
import logoTransparent from "../../asset/image/logo_transparent.png";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import { Loading } from "../loading/loading";
import { Header } from "../header";
import { signUpActions } from "../../module/sign_up";
import { coreActions } from "../../module/core";
import { deleteUser, getAuth, signOut } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export function EasySignUp() {
  const isLoading = useAppSelector((state) => state.core.isLoading);

  return <div className="EasySignUp">{isLoading ? <Loading /> : <Body />}</div>;
}

function Body() {
  return (
    <div className={"EasySignUp-Body"}>
      <Header />
      <Title />
      <Background />
      <Required />
      <Inputs />
      <Continue />
    </div>
  );
}

function Title() {
  return (
    <div className="EasySignUp-Title">
      <h1>회원가입(간편)</h1>
    </div>
  );
}

function Background() {
  return (
    <div className="EasySignUp-Background">
      <img src={logoTransparent} />
    </div>
  );
}

function Required() {
  return (
    <div className="EasySignUp-Required">
      <h1>필수</h1>
      <p>*</p>
    </div>
  );
}

function Inputs() {
  return (
    <div className="EasySignUp-Inputs">
      <NameInput />
      <NicknameInput />
      <Gender />
      <DoB />
    </div>
  );
}

function NameInput() {
  return (
    <div className="EasySignUp-NameInput">
      <NameInputHint />
      <NameInputForm />
    </div>
  );
}

function NameInputHint() {
  return (
    <div className="EasySignUp-NameInputHint">
      <h1>아이디</h1>
      <p>*</p>
    </div>
  );
}

function NameInputForm() {
  const name = useAppSelector((state) => state.signUp.name);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (value > 13) {
      alert("아이디가 너무 깁니다.");
    } else {
      dispatch(signUpActions.setName(value));
    }
  };

  return (
    <div className="EasySignUp-NameInputForm">
      <form>
        <input
          type="text"
          placeholder="아이디 (영문, 변경 불가능, 13자 미만)"
          required
          value={name}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function NicknameInput() {
  return (
    <div className="EasySignUp-NicknameInput">
      <NicknameInputHint />
      <NicknameInputForm />
    </div>
  );
}

function NicknameInputHint() {
  return (
    <div className="EasySignUp-NicknameInputHint">
      <h1>닉네임</h1>
      <p>*</p>
    </div>
  );
}

function NicknameInputForm() {
  const nickname = useAppSelector((state) => state.signUp.nickname);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    if (e.target.value.length > 10) {
      alert("닉네임이 너무 깁니다.");
    } else {
      dispatch(signUpActions.setNickname(e.target.value));
    }
  };

  return (
    <div className="EasySignUp-NicknameInputForm">
      <form>
        <input
          type="text"
          placeholder="닉네임 (10자 미만)"
          required
          value={nickname}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function Gender() {
  return (
    <div className="EasySignUp-Gender">
      <GenderInputHint />
      <GenderInputForm />
    </div>
  );
}

function GenderInputHint() {
  return (
    <div className="EasySignUp-GenderInputHint">
      <h1>성별</h1>
    </div>
  );
}

function GenderInputForm() {
  const [gender, setGender] = useState("");

  const onChange = (e: any) => {
    setGender(e.target.value);
  };

  return (
    <div className="EasySignUp-GenderInputForm">
      <form>
        <input
          type="text"
          placeholder="성별 (예: 남성, 여성)"
          value={gender}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function DoB() {
  return (
    <div className="EasySignUp-DoB">
      <DoBInputHint />
      <DoBInputForm />
    </div>
  );
}

function DoBInputHint() {
  return (
    <div className="EasySignUp-DoBInputHint">
      <h1>생년월일</h1>
    </div>
  );
}

function DoBInputForm() {
  const [dob, setDoB] = useState("");

  const onChange = (e: any) => {
    setDoB(e.target.value);
  };

  return (
    <div className="EasySignUp-DoBInputForm">
      <form>
        <input
          type="text"
          placeholder="생년월일 (예: 990101)"
          value={dob}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function Continue() {
  const name = useAppSelector((state) => state.signUp.name);
  const nickname = useAppSelector((state) => state.signUp.nickname);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (name !== "" && nickname !== "") {
      let isDuplicated = false;
      dispatch(coreActions.setIsLoading(true));

      const qName = query(
        collection(firestore, "user"),
        where("name", "==", name),
      );
      await getDocs(qName).then((snapshot) => {
        if (!snapshot.empty) {
          alert("해당 아이디는 이미 사용 중입니다.");

          isDuplicated = true;

          dispatch(coreActions.setIsLoading(false));
        }
      });

      const qNickname = query(
        collection(firestore, "user"),
        where("nickname", "==", nickname),
      );
      await getDocs(qNickname).then((snapshot) => {
        if (!snapshot.empty) {
          alert("해당 닉네임은 이미 사용 중입니다.");

          isDuplicated = true;

          dispatch(coreActions.setIsLoading(false));
        }
      });

      if (!isDuplicated) {
        const uid = auth.currentUser!.uid;

        const userDocRef = doc(firestore, "user", uid);
        await setDoc(userDocRef, {
          uid: uid,
          name: name,
          nickname: nickname,
        })
          .then(() => {
            dispatch(coreActions.setIsLoading(false));

            navigate("/");
          })
          .catch((_) => {
            deleteUser(auth.currentUser!);
            signOut(auth);

            alert("서버와의 통신에 실패하였습니다.");

            dispatch(coreActions.setIsLoading(false));
          });
      }
    } else {
      alert("모두 기입해주십시오.");
    }
  };

  if (name !== "" && nickname !== "") {
    return (
      <div className="EasySignUp-ContinueActive">
        <button onClick={onClick}>가입하기</button>
      </div>
    );
  } else {
    return (
      <div className="EasySignUp-ContinueInactive">
        <button onClick={onClick}>가입하기</button>
      </div>
    );
  }
}
