import { useAppDispatch, useAppSelector } from "../../module/hook";
import { Loading } from "../loading/loading";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { coreActions } from "../../module/core";
import defaultAvatar from "../../asset/image/default_avatar.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./block.css";
import { UserState } from "../../module/user";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import back from "../../asset/image/back.png";
import { blockActions } from "../../module/block";
import { RightSidebar } from "../right_sidebar";

export function Block() {
  const navigate = useNavigate();

  const isLoading = useAppSelector((state) => state.core.isLoading);

  const auth = getAuth();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.currentUser === null) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          dispatch(coreActions.setFocus("block"));
        } else {
          navigate("/login");
        }
      });
    }

    dispatch(coreActions.setFocus("block"));
  }, []);

  return <div className="Block">{isLoading ? <Loading /> : <Body />}</div>;
}

function Body() {
  return (
    <div className="Block-Body">
      <Header />
      <Sidebar />
      <Title />
      <Main />
      <RightSidebar />
    </div>
  );
}

function Title() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <div className="Block-Title">
      <img src={back} onClick={onClick} />
      <h1>차단된 프로필</h1>
    </div>
  );
}

function Main() {
  return (
    <div className="Block-Main">
      <Blockings />
    </div>
  );
}

function Blockings() {
  const blockings = useAppSelector((state) => state.block.blockings);

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const [lastVisible, setLastVisible] = useState<any>(undefined);

  const getBlockings = async () => {
    if (auth.currentUser !== null) {
      let q;
      if (lastVisible === -1) {
        return;
      } else if (lastVisible !== undefined) {
        q = query(
          collection(firestore, "user", auth.currentUser.uid, "block"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(lastVisible),
        );
      } else {
        q = query(
          collection(firestore, "user", auth.currentUser.uid, "block"),
          orderBy("timestamp", "desc"),
          limit(10),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newDocuments = snapshot.docs.map((elem) => elem.data());

        for (const elem of newDocuments) {
          getUser(elem.uid, elem.timestamp);
        }

        if (snapshot.docs.length === 0) {
          setLastVisible(-1);
        } else {
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  const getUser = async (uid: string, timestamp: number) => {
    let avatarUrl = "";
    let name = "";
    let nickname = "";

    const userDocRef = doc(firestore, "user", uid);

    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      avatarUrl = data.avatarUrl || "";
      name = data.name || "";
      nickname = data.nickname || "";
    } else {
      return;
    }

    const blocking: UserState = {
      uid: uid,
      timestamp: timestamp,
      avatarUrl: avatarUrl,
      name: name,
      nickname: nickname,
      isFollowing: false,
      docPoint: 0,
      docRanking: 0,
      commentPoint: 0,
      commentRanking: 0,
      totalPoint: 0,
      totalRanking: 0,
    };

    dispatch(blockActions.appendBlocking(blocking));
  };

  useEffect(() => {
    dispatch(blockActions.resetBlockings());

    setLastVisible(undefined);

    getBlockings();
  }, []);

  useBottomScrollListener(getBlockings);

  const b = blockings.map((item, _) => {
    return <Blocking blocking={item} />;
  });

  if (b.length > 0) {
    return <div className="Block-Blockings">{b}</div>;
  } else {
    return (
      <div className="Block-Blockings-Void">
        <p>차단된 프로필이 없습니다.</p>
      </div>
    );
  }
}

function Blocking(props: { blocking: UserState }) {
  return (
    <div className="Block-Blocking">
      <UserHeader user={props.blocking} />
    </div>
  );
}

function UserHeader(props: { user: UserState }) {
  return (
    <div className="Block-UserHeader">
      <UserAvatar user={props.user} />
      <UserNames user={props.user} />
      <UserUnblock user={props.user} />
    </div>
  );
}

function UserAvatar(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.avatarUrl !== "") {
    return (
      <div className="Block-UserAvatar">
        <img src={props.user.avatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Block-UserAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function UserNames(props: { user: UserState }) {
  return (
    <div className="Block-UserNames">
      <UserName user={props.user} />
      <UserNickname user={props.user} />
    </div>
  );
}

function UserName(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.name !== "") {
    return (
      <div className="Block-UserName">
        <button onClick={onClick}>{props.user.name}</button>
      </div>
    );
  } else {
    return (
      <div className="Block-UserName">
        <button onClick={onClick}>이름 없음</button>
      </div>
    );
  }
}

function UserNickname(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.nickname !== "") {
    return (
      <div className="Block-UserNickname">
        <button onClick={onClick}>{props.user.nickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Block-UserNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function UserUnblock(props: { user: UserState }) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    dispatch(coreActions.setIsLoading(true));

    const docRef = doc(
      firestore,
      "user",
      auth.currentUser!.uid,
      "block",
      props.user.uid,
    );

    await deleteDoc(docRef);

    dispatch(coreActions.setIsLoading(false));
  };

  return (
    <div className="Block-UserUnblock">
      <button onClick={onClick}>차단해제</button>
    </div>
  );
}
